import React, { useEffect, useMemo, useState } from "react";
import { Button, CircularProgress, Dialog, Grid, makeStyles, ThemeProvider, Typography } from "@material-ui/core";

import TagTemplateFilter from "../../general/TagTemplateFilter";
import ServicesDialog from "../services/ServicesDialog";
import ServicesDialogGeneradores from "../services/ServicesDialogGeneradores";
import AddIcon from "@material-ui/icons/Add";
import { themeV2 } from "../../../styles/common";
import SearchCompany from "../SearchCompany";
import usePlanState from "../../../store/planState";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import InfiniteScroll from "react-infinite-scroll-component";
import useGlobalState from "../../../store/globalState";
import useDashboardUsersState from "../../../store/dashboardUsersState";
import { CompaniesService } from "../../../core/services/companies.service";
import CardDashboardUser from "../dashboard-users/CardDashboardUser";
import UserShowContact from "../dashboard-users/users-dialog-contents/UserShowContact";
import UserDeleteConfirm from "../dashboard-users/users-dialog-contents/UserDeleteConfirm";
import UserDeletedAlert from "../dashboard-users/users-dialog-contents/UserDeletedAlert";
import { useHistory } from "react-router";

interface UsersProps {
  users: CompanyCollaborator[];
  title: string;
  userType: "proveedor" | "cliente";
  filters?: UserFilter[];
  hasServices?: boolean;
  onAdd: () => void;
  onToggle?: (id: string, enabled: boolean) => void;
  onEdit?: (user: any) => void;
  loading?: boolean;
  onSearch?: (query: string) => void;
  onAnswerInvitation?: (processId: string, answer: boolean) => void;
}

const DashboardUsersProvider = ({
  users,
  userType,
  filters,
  title,
  onAdd,
  onEdit,
  onToggle,
  onSearch,
  onAnswerInvitation,
  hasServices,
  loading,
}: UsersProps) => {
  const classes = useStyles();
  const history = useHistory();

  const { deleteProviderOrClient, clients, setClients, providers, setProviders, setActivatedGenerator } = useDashboardUsersState();

  const [showUsersType, setShowUsersType] = useState<UserFilterType>("all");
  const [openDialogProviderServices, setOpenDialogProviderServices] = useState(false);
  const [selectedCollaborator, setSelectedCollaborator] = useState<CompanyCollaborator>();
  const [showedItemsCount, setShowedItemsCount] = useState(20);

  //card states
  const [currentContact, setCurrentContact] = useState<any>(null);
  const [userToDelete, setUserToDelete] = useState<CompanyCollaborator>(null);
  const [showDialog, setShowDialog] = useState(false);
  const [userDeleted, setUserDeleted] = useState<"success" | "error" | null>(null);

  // Generadores
  const { additionalProducts } = useGlobalState();
  const [isGenerator, setIsGenerator] = useState(false);

  useEffect(() => {
    for (const item of (additionalProducts as string[])) {
      if (item === "GENERADORES") setIsGenerator(true)
    }
  }, [additionalProducts]);

  const handleCloseDialog = () => {
    setCurrentContact(null);
    setUserToDelete(null);
    setUserDeleted(null);
    setShowDialog(false);
  };

  function onShowContact(user: DashboardUser) {
    setCurrentContact(user);
    setShowDialog(true);
  }

  const handleCardDelete = (userCompany: any) => {
    setUserToDelete(userCompany);
    setShowDialog(true);
  };

  const handleConfirmDelete = async (pendingStatus: any) => {
    const type = userType === "proveedor" ? "provider" : userType === "cliente" ? "client" : null;
    let data;
    if (pendingStatus === "REQUEST")
      data = await CompaniesService.getInstance().removePendingCollaborator(
        userToDelete?.invitationId,
        userToDelete?.companySelf?.id,
        mainCompany.id
      );
    else data = await deleteProviderOrClient(type, userToDelete?.companySelf?.id);
    handleCloseDialog();

    if (data?.codigo === 0) {
      setUserDeleted("success");
    } else {
      setUserDeleted("error");
    }
    setShowDialog(true);
  };

  const handleDeletedAlertSubmit = (userCompany: any) => {
    handleCloseDialog();
    const type = userType === "proveedor" ? "provider" : userType === "cliente" ? "client" : null;
    if (type === "provider") {
      setProviders(providers.filter((provider) => provider.companySelf.id !== userCompany.id));
    } else if (type === "client") {
      setClients(clients.filter((client) => client.companySelf.id !== userCompany.id));
    }
    setShowDialog(false);
  };

  const mainCompany = useGlobalState((s) => s.mainCompany);
  const canCreateCollaborator = usePlanState((s) => (userType === "cliente" ? s.canCreateClients : s.canCreateProviders));

  const handleClickUser = (user: CompanyCollaborator) => {
    setOpenDialogProviderServices(true);
    setSelectedCollaborator(user);
  };

  const usersToShow = useMemo(() => {
    return users?.slice(0, showedItemsCount);
  }, [users, showedItemsCount]);

  const hasMoreItems = users?.length > showedItemsCount;

  const toggleGenerator = (flag: boolean) => {
    setActivatedGenerator(flag);
  }

  function showMoreItems() {
    setShowedItemsCount(showedItemsCount + 20);
  }
  console.log("home clients generadores")

  return (
    <Grid className="my-3" container spacing={2} style={{ minHeight: "200px" }}>
      <Grid item xs className={classes.row}>
        <div style={{ marginRight: "2rem" }}>
          <Button size="small" startIcon={<ChevronLeftIcon />} variant="contained" onClick={() => history.push('/dashboard/home')}>
            Regresar
          </Button>
        </div>
        <div className={classes.titleWrapper}>
          <Typography variant="h4">{title}</Typography>
        </div>
        {canCreateCollaborator && (
          <div>
            {/*<Button variant="contained"
              onClick={() => { onAdd(); toggleGenerator(false) }}
              className={classes.newUserBtn} startIcon={<AddIcon />}>
              {`Agregar nuevo ${userType}`}
        </Button>*/}
            {isGenerator && (
              <Button variant="contained" color="primary"
                onClick={() => { onAdd(); toggleGenerator(true) }} className={`${classes.newUserBtn} ml-3`} startIcon={<AddIcon />}>
                {`Agregar nuevo ${userType} generador`}
              </Button>
            )}
          </div>
        )}
      </Grid>
      {!!onSearch && (
        <Grid item style={{ height: "max-content" }}>
          <SearchCompany onSearch={onSearch} />
        </Grid>
      )}

      <Grid item container sm={12} direction="row">
        <TagTemplateFilter label="Todos" selected={showUsersType === "all"} count={users.length} onClick={() => setShowUsersType("all")} />
        {filters?.map((filter, i) => (
          <TagTemplateFilter
            key={i}
            label={filter.label}
            selected={showUsersType === filter.type}
            count={users.filter(({ companySelf }) => companySelf?.type === filter?.type).length}
            onClick={() => setShowUsersType(filter.type)}
          />
        ))}
      </Grid>

      {loading ? (
        <div className={classes.placeholderWrapper}>
          <CircularProgress color="primary" />
        </div>
      ) : !users.length && !loading ? (
        <div className={classes.placeholderWrapper}>
          <Typography variant="subtitle2">No se encontraron {userType === "proveedor" ? "proveedores" : "clientes"}</Typography>
        </div>
      ) : (
        <>
          <InfiniteScroll
            style={{ width: "100%", overflow: "hidden" }}
            dataLength={usersToShow?.length}
            next={showMoreItems}
            hasMore={hasMoreItems}
            loader={null}
          >
            <Grid container spacing={2}>
              {usersToShow?.map((user, index) => {
                if (user.companySelf.type === showUsersType || showUsersType === "all") {
                  return (
                    <Grid lg={6} xs={12} item key={user.companySelf.id}>
                      <CardDashboardUser
                        onShowContact={onShowContact}
                        onDelete={handleCardDelete}
                        mainCompanyName={mainCompany?.name}
                        user={user}
                        invitationId={user.invitationId}
                        pendingStatus={user.type as "REQUEST" | "INVITATION"}
                        userType={userType}
                        onToggleActivate={onToggle}
                        onEdit={onEdit}
                        onClick={() => hasServices && handleClickUser(user)}
                        onAnswerCollaboration={onAnswerInvitation}
                        blackList={
                          user.companySelf.blacklist?.status
                            ? "PERTENECE"
                            : user.companySelf.blacklist?.status === false
                              ? "NO PERTENECE"
                              : "BAJO REVISIÓN"
                        }
                      />
                    </Grid>
                  );
                } else return null;
              })}
            </Grid>
          </InfiniteScroll>
          {openDialogProviderServices && (
            <ThemeProvider theme={themeV2}>
              {/**ServicesDialogGeneradores */}
              <ServicesDialogGeneradores
                open={openDialogProviderServices}
                onCancel={() => setOpenDialogProviderServices(false)}
                collaborator={selectedCollaborator}
                userType={userType}
              />
            </ThemeProvider>
          )}

          {showDialog && (
            <ThemeProvider theme={themeV2}>
              <Dialog open={showDialog} fullWidth maxWidth="md">
                {currentContact && <UserShowContact contact={currentContact} onCancel={handleCloseDialog} />}
                {userToDelete && (
                  <UserDeleteConfirm
                    user={userToDelete?.companySelf}
                    userType={userType}
                    onCancel={handleCloseDialog}
                    onSubmit={handleConfirmDelete}
                    disabled={loading}
                  />
                )}
                {userDeleted && (
                  <UserDeletedAlert
                    success={userDeleted}
                    userType={userType}
                    onCancel={handleCloseDialog}
                    onSubmit={handleDeletedAlertSubmit}
                  />
                )}
              </Dialog>
            </ThemeProvider>
          )}
        </>
      )}
    </Grid>
  );
};
export default DashboardUsersProvider;

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    height: "max-content",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  newUserBtn: {
    textTransform: "unset",
    minWidth: "240px",
    color: "#fff",
    backgroundColor: theme.palette.purple.main,
    "&:hover": {
      backgroundColor: theme.palette.purple.dark,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
    },
  },
  titleWrapper: {
    color: theme.palette.black.main,
    minWidth: "200px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "unset",
    },
  },
  placeholderWrapper: {
    width: "100%",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
