import { useEffect, useState } from "react";
import { Box, Button, Collapse, IconButton, makeStyles } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos, Delete, ExpandLess, ExpandMore } from "@material-ui/icons";
import DropzoneFiles from "../../inputs/DropzoneFiles";
import TextField from "../../inputs/inputs-v2/RenderTextFieldV2";
import { GeneradoresServices } from "../../../core/services/generadores.service";
import useCustomToast from "../../../core/hooks/useCustomToast";
import useUiState from "../../../store/uiState";
import useGeneralFormCompleteState from "../../../store/generadores/generalFormState";

interface RenderCarouselProps {
    label: string;
    imagesArray: any[];
    imagesArraySecond: any[];
    imagesArrayThird: any[];
    setImagesArray: any;
    formik: any;
    list: string;
    limit: boolean;
};


const CarouselPhotographic = ({
    label,
    imagesArray,
    imagesArraySecond,
    imagesArrayThird,
    setImagesArray,
    formik,
    list,
    limit
} : RenderCarouselProps) => {
    const classes = useStyles();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [open, setOpen] = useState(false);
    const toast = useCustomToast();
    const { setShowGenericLoader } = useUiState();
    const idForm = useGeneralFormCompleteState((state) => state.idForm);

    useEffect(() => {

    }, [imagesArray])

    const handleOpen = () => {
        setOpen(!open);
    };

   /* const handleAddImagssse = async (file: any) => {
      setShowGenericLoader(true);
      const newImage = { imagen: file, activities: '', ns: '', imageId: ''};
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        const formData = new FormData();
        formData.append('file', file);
        await GeneradoresServices.getInstance().postImage(formData).then(async (data) => {
          newImage.imageId = data.respuesta;
          toast.success("Imagén guardada con éxito.");
        }).catch((error) => {
          toast.error("Ha ocurrido un error, vuelva ha subir la imagén.");
        }); 
      }
      console.log("image new",newImage)
      setImagesArray([...imagesArray, newImage]);
      console.log("#imga array", imagesArray)
      setCurrentIndex(imagesArray.length);
      setShowGenericLoader(false);
    };*/


    const handleAddImage = async (file: any) => {
      setShowGenericLoader(true);
      const newImage = { imagen: file, activities: '', ns: '', imageId: '' };
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        const formData = new FormData();
        formData.append('file', file);
        try {
          const data = await GeneradoresServices.getInstance().postImage(formData);
          newImage.imageId = data.respuesta;
          
          // Combina los arrays en uno solo para verificar duplicados
          const combinedImagesArray = [...imagesArray, ...imagesArraySecond, ...imagesArrayThird];
    
          // Verifica si el imageId ya existe en cualquiera de los arrays
          const isDuplicate = combinedImagesArray.some(image => image.imageId === newImage.imageId);
          if (isDuplicate) {
            toast.error("Estimado usuario, la imagen es repetida y no son permitidas.");
          } else {
            setImagesArray([...imagesArray, newImage]);
            setCurrentIndex(imagesArray.length);
            toast.success("Imagen guardada con éxito.");
          }
        } catch (error) {
          toast.error("Ha ocurrido un error, vuelva a subir la imagen.");
        }
      }
      setShowGenericLoader(false);
    };
    
    
    const handleInputChange = (index: number, field: any, value: any) => {
      const updatedImages = imagesArray.map((img: any, i: number) =>
        i === index ? { ...img, [field]: value } : img
      );
      setImagesArray(updatedImages);
    };

    const handleDeleteImage = async (index: number, imageId: string) => {
      setShowGenericLoader(true);
      const updatedImages = imagesArray.filter((_, i) => i !== index);
      setImagesArray(updatedImages);
      setCurrentIndex((prevIndex) => (prevIndex === index ? 0 : Math.max(0, prevIndex - 1)));
      if (imageId.length === 25) {
        await GeneradoresServices.getInstance().deleteImageList(idForm, imageId, list);
      }
      setShowGenericLoader(false);
    };
  
    const handlePrev = () => {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? imagesArray.length : prevIndex - 1));
    };
  
    const handleNext = () => {
      setCurrentIndex((prevIndex) => (prevIndex === imagesArray.length ? 0 : prevIndex + 1));
    };
    
    return (
        <Box className={classes.dropdown}>
        <Button onClick={handleOpen} endIcon={open ? <ExpandLess /> : <ExpandMore />}>
          {`${label} (${imagesArray.length})`}
        </Button>
        <Collapse in={open}>
          <Box className={classes.carouselContainer}>
            <IconButton onClick={handlePrev}>
              <ArrowBackIos />
            </IconButton>
            <Box className={classes.carouselContent} style={{ position: 'relative', display: 'inline-block',}}>
              {currentIndex < imagesArray.length ? (
                <>
                  <Box className={classes.formContainer}>
                    <div className={classes.leftHalf} >
                      <DropzoneFiles
                        icon={false}
                        accept="image/*"
                        file={imagesArray[currentIndex].imagen}
                        onChange={(file) => {
                          handleAddImage(file);
                          formik.setFieldValue('imageFile', file);
                        }}
                        label=""
                        labelBox="Arrastra la imagen aquí para subirla"
                        disabled={limit}
                      />
                      <Delete 
                        style={{
                          position: 'absolute',
                          top: 10,
                          right: 10,
                          cursor: 'pointer',
                          color: 'white',
                          backgroundColor: '#2347AD',
                          borderRadius: '50%',
                          padding: 5,
                        }}
                        onClick={() => {
                          handleDeleteImage(currentIndex, imagesArray[currentIndex].imageId);
                        }}
                      />
                    </div>
                    <div className={classes.rightHalf}>
                      <TextField
                        disabled={limit}
                        size="small"
                        label="Actividades"
                        error={formik.errors.ont}
                        onChange={(e) => handleInputChange(currentIndex, 'activities', e.target.value)}
                        touched={formik.touched.ont}
                        value={imagesArray[currentIndex]?.activities}
                        placeholder="Actividades *"
                        name="activities"
                        className={classes.textField}
                      />
                      <TextField
                        disabled={limit}
                        size="small"
                        error={formik.errors.ont}
                        onChange={(e) => handleInputChange(currentIndex, 'ns', e.target.value)}
                        touched={formik.touched.ont}
                        value={imagesArray[currentIndex]?.ns}
                        placeholder="NS"
                        name="ns"
                        label="NS"
                        className={classes.textField}
                      />
                      <div className={classes.buttonContainer}>
                      </div>
                    </div>
                  </Box>
                </>
              ) : (
                <>
                  <Box className={classes.formContainer}>
                    <div className={classes.leftHalf}>
                      <DropzoneFiles
                        disabled={limit}
                        accept="image/*"
                        file={null}
                        onChange={(file) => {
                          handleAddImage(file);
                          formik.setFieldValue('imageFile', file);
                        }}
                        label=""
                        labelBox="Arrastra la imagen aquí para subirla"
                      />
                    </div>
                    <div className={classes.rightHalf}>
                      <TextField
                        disabled={limit}
                        size="small"
                        error={formik.errors.ont}
                        onChange={(e) => handleInputChange(currentIndex, 'activities', e.target.value)}
                        touched={formik.touched.ont}
                        value={''}
                        placeholder="Actividades *"
                        name="activities"
                        className={classes.textField}
                      />
                      <TextField
                        disabled={limit}
                        size="small"
                        error={formik.errors.ont}
                        onChange={(e) => handleInputChange(currentIndex, 'ns', e.target.value)}
                        touched={formik.touched.ont}
                        value={''}
                        placeholder="NS"
                        name="ns"
                        className={classes.textField}
                      />
                      <div className={classes.buttonContainer}>
                      </div>
                    </div>
                  </Box>
                </>
              )}
            </Box>
            <IconButton onClick={handleNext}>
              <ArrowForwardIos />
            </IconButton>
          </Box>
        </Collapse>
      </Box>
    );
};

const useStyles = makeStyles((theme) => ({
    dropdown: {
      marginBottom: theme.spacing(2),
    },
    formContainer: {
      display: 'flex',
      padding: theme.spacing(2),
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
    },
    leftHalf: {
      flex: 1,
      marginRight: theme.spacing(2),
    },
    rightHalf: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    textField: {
      marginBottom: theme.spacing(2),
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
    },
    carouselContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    carouselContent: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    carouselImage: {
      maxWidth: '50%',
      maxHeight: '400px',
      objectFit: 'contain',
      marginBottom: '20px',
    },
  }));


export default CarouselPhotographic;