import { Grid, Typography } from "@material-ui/core";
import { Group } from "../../../core/models/stage.models";
import RequirementCard from "./RequirementCard";

const StageGroupCard = ({ group, refresh }: { group: Group; refresh: any }) => {
  return (
    <Grid
      container
      style={{
        backgroundColor: "#fff",
        padding: "1rem",
        marginBottom: "2rem",
      }}
    >
      <Grid item xs={12} style={{ borderBottom: "1px solid #000", paddingBottom: ".5rem" }}>
        <Typography variant="h6">{group.name}</Typography>
      </Grid>

      <Grid
        container
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill,minmax(360px,1fr))",
          gridGap: "1rem",
          marginTop: "1rem",
        }}
      >
        {group.requirements?.map((req) => (
          <RequirementCard key={req.uid} requirement={{ ...req, documents: req.documents?.reverse() }} refresh={refresh} />
        ))}
      </Grid>
    </Grid>
  );
};

export default StageGroupCard;
