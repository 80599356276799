import moment from "moment";
import { FileOrFolder, FolderContent } from "vault-axeleratum/dist/types";

export const mapFolderDataIpfs = (data: FolderContentResponse): FolderContent => {
  //Map the relevant information of the folders retreived by the back-end.
  const info: FileOrFolder = {
    author: data.data.companyId,
    id: data.data.id,
    isFolder: true,
    name: data.data.name,
    folderId: data.data.parentFolderId,
  };

  const prevFolderInfo: FileOrFolder = {
    author: data.prev.companyId,
    id: data.prev.id,
    isFolder: true,
    name: data.prev.name,
    folderId: data.prev.parentFolderId,
  };

  // Map the relevant information of the documents retreived by the back-end.
  const mappedDocument: FileOrFolder[] = data.childrens
    .filter((document) => document.fileType === "document")
    .map((document) => {
      return {
        author: document.owner,
        hash: document.ipfsHash,
        id: document.id,
        isFolder: false,
        lastUpdated: new Date(document.updateAt),
        name: document.name,
        extension: document.extension.startsWith(".") ? document.extension : ".".concat(document.extension),
        folderId: document.folderId,
        ownerId: document.companyId,
        tags: document.tags,
        user: document.userBy,
        description: document.description,
        sha256sum: document.sha256sum,
      };
    });

  const mappedOtherFolders: FileOrFolder[] = data.childrens
    .filter((item) => item.parentFolderId != null)
    .map((item) => {
      return {
        author: item.companyId,
        id: item.id,
        isFolder: true,
        name: item.name,
        folderId: item.parentFolderId,
      };
    });

  let filterFolderItem = mappedOtherFolders.filter((folder) => folder !== undefined);
  sort_by_key(filterFolderItem, "name");

  let mappedDocuments = mappedDocument.filter((document) => document !== undefined);
  sort_by_key(mappedDocuments, "name");

  let content = mappedDocuments.concat(filterFolderItem);

  return { content, info, prevFolderInfo };
};

function sort_by_key(array: any, key: string) {
  return array.sort(function (a: any, b: any) {
    var x = a[key];
    var y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

/**
 * Converts a file to a Base64
 * @param file File obtained from an input
 * @returns Base 64 representation of ´file´.
 */
export const toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });

export function sortData(data: any[]) {
  return data.sort((x, y) => {
    let a = x.name.toLowerCase(),
      b = y.name.toLowerCase();
    return a === b ? 0 : a > b ? 1 : -1;
  });
}

export function parseJwt(token: string) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function capitalizeFirstLetter(string: string) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function sliceText(text: string, maxChars: number) {
  if (!text) return "";
  return text.length > maxChars ? text.slice(0, maxChars) + "..." : text;
}

export function validRfc(rfc: string) {
  const rfcRegex = /^[A-ZÑ&]{3,4}\d{6}[A-V1-9][A-Z\d]{2}$/;
  return rfcRegex.test(rfc);
}

export function chunksArray(arr: any[], chunkSize: number) {
  const chunks = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      chunks.push(chunk);
  }

  return chunks;
}

export const getCalendarDays = (givenDate: string): { dayNumber: number; isCurrentMonthDay: boolean }[][] => {
  // YYYY-MM-DD
  const currentDate = moment(givenDate);
  const startWeek = moment(givenDate).startOf("month").week();
  let endWeek = moment(givenDate).endOf("month").week();
  if (currentDate.month() === 11 && endWeek === 1) {
    endWeek = 53;
  }
  let calendarDays = [];
  for (let week = startWeek; week <= endWeek; week++) {
    calendarDays.push(
      Array(7)
        .fill(0)
        .map((n, i) => {
          const day = moment(givenDate)
            .week(week)
            .startOf("week")
            .clone()
            .add(n + i, "day");

          const dayNumber = day.date();
          const isCurrentMonthDay = day.month() === currentDate.month();

          return { dayNumber, isCurrentMonthDay };
        })
    );
  }

  return calendarDays;
};
