import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, MenuItem, Select, TextField } from "@material-ui/core";
import { useState } from "react";
import useCustomToast from "../../../core/hooks/useCustomToast";
import { Contract } from "../../../core/models/service.models";
import { NTPService } from "../../../core/services/NTPService.service";
import useUiState from "../../../store/uiState";
import CurrencyNumberFormat from "./inputs/CurrencyNumberFormat";
import useGlobalState from "../../../store/globalState";

const EditContractDataDialog = ({ onClose, currentContract, serviceId, refresh }: { onClose: any; currentContract: Contract; serviceId: string; refresh: () => any }) => {
  const [currency, setCurrency] = useState(currentContract.currency);
  const [amount, setAmount] = useState(currentContract.amount);
  const [expirationDate, setExpirationDate] = useState<any>(currentContract.expirationDate);
  const { setShowGenericLoader } = useUiState();
  const { typeService } = useGlobalState(); 

  const toast = useCustomToast();

  async function onSave() {
   if(typeService=== "generators"){
     saveGenerdor();
   }else{
   saveRepse();
   }
  }

  async function saveRepse() {
    const { fileId, name } = currentContract;
    setShowGenericLoader(true);
    try {
      await NTPService.getInstance().updateContract(serviceId, {
        amount,
        currency,
        expirationDate: expirationDate as any,
        fileId,
        name,
      });
      toast.success("Contrato actualizado");
      refresh();
      onClose();
    } catch (error) {
      toast.error("Error al actualizar contrato");
    } finally {
      setShowGenericLoader(false);
    }
  }

  async function saveGenerdor() { 
    const { fileId, name } = currentContract;
    setShowGenericLoader(true);
    try {
      await NTPService.getInstance().updateGeneradorContract(serviceId, {
        amount,
        currency,
        expirationDate: expirationDate as any,
        fileId,
        name,
      });
      toast.success("Contrato actualizado");
      refresh();
      onClose();
    } catch (error) {
      toast.error("Error al actualizar contrato");
    } finally {
      setShowGenericLoader(false);
    }
  }
  

  return (
    <>
      <Dialog open={true} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Modficar datos del contrato</DialogTitle>
        <DialogContent>
          <div>
            <TextField
              type="date"
              fullWidth
              size="small"
              variant="outlined"
              value={expirationDate?.split("T")[0]}
              onChange={(e) => {
                const date = new Date(e.target.value);
                date.setHours(24);
                setExpirationDate(date?.toISOString());
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                marginTop: 16,
              }}
            >
              <FormControl size="small" style={{ minWidth: 80 }}>
                <Select value={currency} onChange={(e) => setCurrency(e.target.value as string)} variant="outlined">
                  <MenuItem value="MXN">MX$</MenuItem>
                  <MenuItem value="USD">$</MenuItem>
                </Select>
              </FormControl>
              <TextField
                type={"text"}
                fullWidth
                size="small"
                variant="outlined"
                value={amount}
                onChange={(e) => setAmount(+e.target.value)}
                InputProps={{
                  inputComponent: CurrencyNumberFormat as any,
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined" color="primary">
            Cancelar
          </Button>
          <Button onClick={onSave} variant="contained" color="primary">
            Aplicar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditContractDataDialog;
