import { Button, Menu, Typography } from "@material-ui/core";
import usePlanState from "../../store/planState";
import PdfIcon from "../icons/icons-v2/PdfIcon";

const HelpMenu = ({
  anchorEl,
  onClose,
  handleDownload,
}: {
  anchorEl: HTMLElement;
  onClose: () => void;
  handleDownload: (doc: "corp" | "docsGuide" | "validations") => any;
}) => {
  const { plan } = usePlanState();
  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      <div style={{ width: "400px", padding: "16px" }}>
        <Typography color="primary" style={{ fontSize: "32px" }}>
          Ayuda
        </Typography>
        <Typography style={{ fontSize: "16px", marginTop: "20px" }}>
          Hola, hemos creado materiales de ayuda para que sea más fácil tu aprendizaje en la plataforma.
        </Typography>
        <div style={{ marginTop: "16px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          {plan === "CORPORATIVO" && (
            <DocItem docName={"Manual de uso para plan coorporativo."} onDownload={() => handleDownload("corp")} />
          )}
          <DocItem docName={"Guía de documentos."} onDownload={() => handleDownload("docsGuide")} />
          <DocItem docName={"Restricciones de validaciones."} onDownload={() => handleDownload("validations")} />
        </div>
      </div>
    </Menu>
  );
};

export default HelpMenu;

const DocItem = ({ docName, onDownload }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
      <PdfIcon />
      <Typography style={{ fontSize: "14px", maxWidth: "130px", textAlign: "center" }}>{docName}</Typography>
      <Button variant="contained" color="primary" onClick={onDownload}>
        Descargar
      </Button>
    </div>
  );
};
