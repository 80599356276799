/* eslint-disable no-useless-escape */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Button,
  Grid,
  Typography,
  makeStyles,
  Backdrop,
  CircularProgress,
  Modal,
  Box,
  } from "@material-ui/core";
import * as Yup from "yup";
import TextField from "../../inputs/inputs-v2/RenderTextFieldV2";
import { useFormik } from "formik";
import LogoutOnLeave from "../../LogoutOnLeave";
import DropzoneFiles from "../../inputs/DropzoneFiles";
import RedPinIcon from "../../icons/RedPinIcon";
import useGeolocationFormState from "../../../store/generadores/geolocationFormState";
import useGeneralFormCompleteState from "../../../store/generadores/generalFormState";
import { GeneradoresServices } from "../../../core/services/generadores.service";
import useCustomToast from "../../../core/hooks/useCustomToast";
import useUiState from "../../../store/uiState";
import LinearProgress from '@material-ui/core/LinearProgress';
import { ShopSharp } from "@material-ui/icons";
import useGeolocationFiletate from '../../../store/generadores/geolocationFileState';
type FormFields = {
  id: string,
  date: string;
  address: string;
  code: string;
  name: string;
  coordinates: string;
  folioTracker: string;
  imageFile: any;
};

function base64ToObjectURL(base64) {
  // Decodificar el string base64 y convertirlo en binario
  let byteString = atob(base64);
  let ab = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // Crear un Blob a partir de los datos binarios
  let blob = new Blob([ab], { type: 'image/png' });

  // Crear un ObjectURL a partir del Blob
  let url = URL.createObjectURL(blob);

  return url;
}

const FormGeolocation = React.memo(() => {
  const generalFormBody = useGeneralFormCompleteState((state) => state.generalFormBody);
  const geolocationFormBody = useGeolocationFormState((state) => state.geolocationFormBody);
  const classes = useStyles();
  const toast = useCustomToast();
  const [date, setDate] = useState<any>(geolocationFormBody.date);
  const updateGeolocationBody = useGeolocationFormState((state) => state.updateGeolocationForm);
  const updateFinalImageId = useGeolocationFormState((state) => state.updateFinalImageId);
  const idForm = useGeneralFormCompleteState((state) => state.idForm);
  const { setShowGenericLoader } = useUiState();
  const [showLoader, setShowLoader] = useState(false);

  const { imageFile, setImageFile } = useGeolocationFiletate();

  const getImage = useCallback(async () => {
    setShowGenericLoader(true);
    setShowLoader(true);
    try {
      const data = await GeneradoresServices.getInstance().getImage(geolocationFormBody.finalImageId);
      const file = base64ToObjectURL(data.file);
      formik.setFieldValue('imageFile', file);
      setImageFile(file); // Actualizar el estado de la imagen
    } catch (error) {
      console.error(error);
    } finally {
      setShowGenericLoader(false);
      setShowLoader(false);
    }
  }, [geolocationFormBody.finalImageId, setImageFile]);

  useEffect(() => {
    if (generalFormBody.address === '' || generalFormBody.address === null) {
      toast.info("Valide la información del formulario general para continuar.");
    }

    if (geolocationFormBody.finalImageId !== "" && geolocationFormBody.finalImageId !== null) {
      getImage();
    }
  
  }, [generalFormBody.address, geolocationFormBody.finalImageId, getImage]);

  const handleSubmit = async (values: any) => {
    setShowGenericLoader(true);
    const body: any = {
      date: values.date,
      address: values.address,
      code: values.code,
      coordinates: values.coordinates,
      folioTracker: values.folioTracker,
      finalImageId: geolocationFormBody.finalImageId,
    };
    console.log(body);
    updateGeolocationBody(body);
    try {
      await GeneradoresServices.getInstance().patchGeolocationForm(idForm, body).then((data) => {
        setShowGenericLoader(false);
        toast.success("Información guardada con éxito.");
      });
    } catch (error) {
      setShowGenericLoader(false);
      toast.error("Ha ocurrido un error, verifique la información.");
    }

  };

  const formik = useFormik<FormFields>({
    initialValues: {
      id: generalFormBody.idCode,
      date: geolocationFormBody.date,
      address: generalFormBody.address,
      code: generalFormBody.idSupplier,
      name: generalFormBody.name,
      coordinates: geolocationFormBody.coordinates,
      folioTracker: geolocationFormBody.folioTracker,
      imageFile: null,
    },
    onSubmit: handleSubmit,
    validationSchema: Yup.object({
      date: Yup.date().required("Campo requerido"),
      address: Yup.string().required("Campo requerido"),
      code: Yup.string().required("Campo requerido"),
      name: Yup.string().required("Campo requerido"),
      coordinates: Yup.string().required("Campo requerido"),
      folioTracker: Yup.string().required("Campo requerido"),
    }),
  });
  
  useEffect(() => {
    const handleImageUpload = async () => {
      setShowGenericLoader(true);
      if (typeof formik.values.imageFile !== 'string') {
        const file = formik.values.imageFile;
        if (file) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          const formData = new FormData();
          formData.append('file', file);
          try {
            const data = await GeneradoresServices.getInstance().postImage(formData);
            const patchData = await GeneradoresServices.getInstance().patchGeolocationImage(idForm, data.respuesta);
            updateFinalImageId(patchData.respuesta.geolocation.finalImageId);
          } catch (error) {
            console.log(error);
          } finally {
            setShowGenericLoader(false);
          }
        } else {
          setShowGenericLoader(false);
        }
      } else {
        setShowGenericLoader(false);
      }
    };
  
    handleImageUpload();
  }, [formik.values.imageFile]);
  

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <LogoutOnLeave />
      <Grid container>
        <Grid item md={12}>
          <div className={classes.subContainer}>
            <div className={classes.inputDoubleRow}>
              <TextField
                label="RFC"
                size="small"
                error={formik.errors.id}
                onChange={formik.handleChange}
                touched={formik.touched.id}
                value={formik.values.id}
                placeholder="ID *"
                name="id"
                disabled={true}
              />

              <TextField
                label="Fecha"
                size="small"
                error={formik.errors.date}
                touched={formik.touched.date}
                value={date?.split("T")[0]}
                onChange={(e) => {
                  const date = new Date(e.target.value);
                  date.setHours(24);
                  setDate(date?.toISOString().split("T")[0]);
                  formik.setFieldValue('date', date?.toISOString().split("T")[0]);
                }}
                type="date"
                name="date"
                minDate="2024-05-01"
              />
            </div>

            <div className={classes.inputDoubleRow}>

              <TextField
                size="small"
                error={formik.errors.name}
                onChange={formik.handleChange}
                touched={formik.touched.name}
                value={formik.values.name}
                label="Razón social"
                placeholder="Razón social"
                name="name"
                disabled={true}
              />

              <TextField
                size="small"
                label="ID del proveedor"
                error={formik.errors.code}
                onChange={formik.handleChange}
                touched={formik.touched.code}
                value={formik.values.code}
                placeholder="Code"
                name="code"
                disabled={true}
              />
            </div>

            <TextField
                size="small"
                error={formik.errors.address}
                onChange={formik.handleChange}
                touched={formik.touched.address}
                value={formik.values.address}
                label="Dirección del cliente"
                placeholder="Dirección del cliente *"
                name="address"
                disabled={true}
              />

            <div className={classes.inputDoubleRow}>
              <TextField
                size="small"
                error={formik.errors.coordinates}
                onChange={formik.handleChange}
                touched={formik.touched.coordinates}
                value={formik.values.coordinates}
                label="Coordenadas"
                placeholder="Coordenadas del cliente *"
                name="coordinates"
              />

              <TextField
                size="small"
                error={formik.errors.folioTracker}
                onChange={formik.handleChange}
                touched={formik.touched.folioTracker}
                value={formik.values.folioTracker}
                placeholder="Folio tracker *"
                label="Folio tracker"
                name="folioTracker"
              />
            </div>

            <div className={classes.imageField}>
              {showLoader ? (
                <div >
                  <LinearProgress/>
                  Cargando...
                </div>
              ) : (
                <DropzoneFiles
                  icon={true}
                  modal={true}
                  accept="image/*"
                  file={formik.values.imageFile}
                  onChange={(file) => {
                    console.log(file);
                    formik.setFieldValue('imageFile', file);
                  }}
                  label=""
                  labelBox="Arrastra la imagen aquí para subirla"
                />
              )}
            </div>
          </div>

          <Grid container item xs={12} justifyContent="center">
            <div className="text-center">
              <Typography style={{ display: "block" }} variant="caption">
                * Campos obligatorios
              </Typography>
              <Button type="submit" className="mt-2 ml-2" variant="contained" color="primary">
                GUARDAR
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
});

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  subContainer: {
    marginBottom: theme.spacing(2),
  },
  inputDoubleRow: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gap: "1rem",
  },
  imageField: {
    marginTop: '5%',
  },
}));

export default FormGeolocation;
