import React, { useEffect, useState } from "react";
import { Route, useHistory, useRouteMatch } from "react-router";
import { Vault } from "vault-axeleratum";
import { Company, FormDocumentFields, HistoryPathItem, VaultDocument } from "vault-axeleratum/dist/types";
import { BlockchainService } from "../../core/services/blockchain.service";
import { DocumentsService } from "../../core/services/documets.service";
import { FoldersService } from "../../core/services/folders.service";
import { CompaniesService } from "../../core/services/companies.service";
import { mapFolderDataIpfs, sortData, toBase64 } from "../../core/utils/utils";
import { useSearchParams } from "../../core/hooks/useSearchParams";
import { useBrowserButtons } from "../../core/hooks/useBrowserButtons";
import AlertContinue from "../UI/AlertContinue";

const VaultWrapper = () => {
  const { path } = useRouteMatch(); //Used to get the current route: /dashboard/vault
  let match = useRouteMatch<{ folderId?: string }>({ path: "/dashboard/vault/:folderId" }); //Used to get the child route: /dashboard/vault/:folderId
  let [searchParams, setSearchParams] = useSearchParams({ company: "" });
  const history = useHistory();
  useBrowserButtons(
    () => setCurrentFolderIndex((value) => value - 1),
    () => setCurrentFolderIndex((value) => value + 1)
  );

  const [companies, setCompanies] = useState<Company[]>([]); // List of companies retreived by the back-end.
  const [selectedCompany, setSelectedCompany] = useState(""); //id of the selected company.
  const [historyPath, setHistoryPath] = useState<HistoryPathItem[]>([]);
  const [currentFolderId, setCurrentFolderId] = useState("");
  const [currentFolderIndex, setCurrentFolderIndex] = useState(1);
  const [showAlertContinue, setShowAlertContinue] = useState(false);

  const companiesService = CompaniesService.getInstance();
  const foldersService = FoldersService.getInstance();
  const documentsService = DocumentsService.getInstance();
  const blockchainService = BlockchainService.getInstance();

  //Effect fired when there is an url param that represents the folder id of a selected folder.
  //If it exist, the content of that folder will be loaded on `documents` state variable, otherwise,
  //there will be rendered the content of the company root folder
  useEffect(() => {
    const folderId = match?.params.folderId;
    const company = searchParams.get("company");
    if (company) {
      setSelectedCompany(company);
    } else {
      setSelectedCompany("");
      sessionStorage.removeItem("currentPathIndex");
      setCurrentFolderIndex(1);
      sessionStorage.removeItem("historyPath");
      setHistoryPath([]);
    }
    setCurrentFolderId(folderId ? folderId : "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match?.params.folderId, searchParams.get("company")]);

  // This effects gets the history and the current folder index from the session if they exist.
  useEffect(() => {
    getCompanies();
    const savedIndex = sessionStorage.getItem("currentPathIndex");
    if (savedIndex) setCurrentFolderIndex(parseInt(savedIndex));

    const savedPath = sessionStorage.getItem("historyPath");
    if (savedPath) setHistoryPath(JSON.parse(savedPath));

    setShowAlertContinue(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Function that gets all the active companies from the back-end and stores them on the state. */
  const getCompanies = () => {
    companiesService
      .getActiveCompanies()
      .then((resp) => {
        // Map the relevant company information for the screen, in this case, the id and the name of the company.
        const companiesData = resp.companies.map(
          (business: any, i: any): Company => ({
            id: business.id ? business.id : `${i}`,
            name: business.name,
          })
        );
        setCompanies(sortData(companiesData));
      })
      .catch(console.log);
  };

  /**
   * Function that sets `selectedCompany` state variable, either by an a select input or by url params.
   * @param {string} companyId Id of the selected company
   * the company folder content.
   */
  const handleSelectCompany = async (companyId: string) => {
    setSelectedCompany(companyId);
    setSearchParams({ company: companyId });
  };

  /**
   * Function that load a folder content to be sent to the library
   * @param folderId Company id whose connection to Kaleido wants to be verified.
   */
  const handleLoadFolderContent = (folderId?: string) => {
    return folderId
      ? foldersService.getDocumentsByFolder(folderId, selectedCompany).then(mapFolderDataIpfs)
      : foldersService.getFoldersCompanies(selectedCompany).then(mapFolderDataIpfs);
  };

  /**
   *
   * @param formData Form data body to be sent to the back end
   * @param folderId Folder where the document will be uploaded
   * @returns a Promise when the proccess to upload document is done.
   */
  const handleCreateDocument = async (formData: FormDocumentFields, folderId: string) => {
    try {
      const base64 = await toBase64(formData.file as File);
      const jsonData = {
        folderId: folderId !== "" ? folderId : undefined,
        companyId: selectedCompany,
        name: formData.name ? formData.name : "",
        description: formData.description,
        extension: /(?:\.([^.]+))?$/.exec(formData.file!.name)![0],
        fileBase64: base64.replace(/^.+?;base64,/, ""),
        status: true,
      };
      const documentData = await documentsService.createDocument(jsonData);
      const jsonKaleido = {
        fileId: documentData.id,
        fileName: documentData.name,
        user: documentData.userBy,
        company: documentData.companyId,
        ipfsHash: documentData.ipfsHash,
        sha256Sum: documentData.sha256sum,
      };
      return await blockchainService.createDocumentKaleidoContract(jsonKaleido);
    } catch (error) {
      throw error;
    }
  };

  const handleCreateFolder = (folderName: string, parentFolderId: string) => {
    const jsonData = {
      name: folderName,
      parentFolderId: parentFolderId !== "" ? parentFolderId : undefined,
      companyId: selectedCompany,
      isActive: true,
    };
    return documentsService.createFolder(jsonData);
  };

  const handleDeleteItemInFolder = async (itemId: string, isFolder: boolean) => {
    try {
      isFolder ? documentsService.deleteFolder(itemId) : documentsService.deleteDocument(itemId);
    } catch (error) {
      throw error;
    }
  };

  const handleOpenFolder = (folderId: string, historyPath?: HistoryPathItem[], index?: number, backButtonPressed?: boolean) => {
    setCurrentFolderId(folderId);
    if (historyPath && index) {
      setHistoryPath(historyPath);
      sessionStorage.setItem("historyPath", JSON.stringify(historyPath));
      setCurrentFolderIndex(backButtonPressed ? index + 1 : index);
      sessionStorage.setItem("currentPathIndex", index.toString());

      if (backButtonPressed) history.go(index - currentFolderIndex);
      else history.push({ pathname: `/dashboard/vault/${folderId}`, search: history.location.search });
    }
  };

  const handleOpenDocument = async (document: VaultDocument) => {
    try {
      const docContent = await documentsService.downloadDocument(document.id);
      try {
        await addDocumentActionsHistory(document);
      } catch (error) {
        console.log("Error on set history");
      }
      return docContent;
    } catch (error) {
      throw error;
    }
  };

  const addDocumentActionsHistory = async (document: VaultDocument) => {
    let json: HistoryBlockChainReqBody = {
      action: "READ",
      fileId: document.id,
      user: sessionStorage.getItem("currentUser") ?? "",
      fileName: document.name + document.extension,
    };
    try {
      await blockchainService.addDocumentHistoryKaliedoContract(json);
    } catch (error) {
      throw error;
    }
  };

  const handleGetHistoryItem = async (itemId: string) => {
    await blockchainService.getDocumentInitOwner(itemId);
    const data = await blockchainService.getHistoryDocument(itemId);
    return data.history.map((history) => ({
      user: history.user,
      date: parseInt(history.date) * 1000,
      action: history.action,
      hash: history.accountAddress,
    }));
  };
  return (
    <Route path={[`${path}/:folderId`, path]}>
      <>
        <Vault
          companies={companies}
          onLoadFolderContent={selectedCompany ? handleLoadFolderContent : undefined}
          selectedCompany={selectedCompany}
          onChangeSelectedCompany={handleSelectCompany}
          currentFolderId={currentFolderId}
          onOpenDocument={handleOpenDocument}
          onOpenFolder={handleOpenFolder}
          onDeleteItem={handleDeleteItemInFolder}
          onCreateFolder={handleCreateFolder}
          onCreateDocument={handleCreateDocument}
          onGetItemHistory={handleGetHistoryItem}
          path={historyPath}
          currentFolderPathIndex={currentFolderIndex}
        />
        {showAlertContinue && (
          <AlertContinue
            title="IMPORTANTE"
            text="Estás por entrar a un apartado que te permitirá visualizar la información que has subido, sin embargo, cualquier documento que subas aquí NO modificará el estado de tu cumplimiento. 
            Si deseas cargar tu información REPSE, selecciona el apartado de inicio, el cliente o proveedor según corresponda e ingresa al servicio correspondiente."
            severity="info"
            onCancel={() => setShowAlertContinue(false)}
            onSubmit={() => setShowAlertContinue(false)}
          />
        )}
      </>
    </Route>
  );
};

export default VaultWrapper;
