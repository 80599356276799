import create, { GetState } from "zustand";
import { NamedSet } from "zustand/middleware";
import { UserInfo } from "../types/global";

type Plan = "TEMP" | "FREE" | "PYME" | "CORPORATIVO" | "PREMIUM"; //TODO: revisar

interface State {
  plan: Plan;
  invitedAs: "provider" | "client";
  invited: boolean;
  canCreateClients: boolean;
  canCreateProviders: boolean;
  canDeleteClients: boolean;
  canDeleteProviders: boolean;
  canHandleServices: {
    onClients: boolean;
    onProviders: boolean;
  };
  canUploadDocuments: {
    onExpedient: boolean;
    onCumplimiento: boolean;
  };
  canValidateDocuments: {
    whenIsClient: boolean;
    whenIsProvider: boolean;
  };
  canDeleteDocuments: boolean;
  canAccessVault: boolean;
  canAccesAdministation: boolean;
  canAccessRiesgos: boolean;
  canAccessReport: boolean;
  canAccessStripe: boolean;
  canConsultService: boolean;
  canConsultDocuments: boolean;
  canCreateAdmin: boolean;
  canEditCompany: boolean;
  canInvoicePayments: boolean;
  canHandlePlan: boolean;
  canShowHomeStages: boolean;
  canUploadUpdateContract: boolean;
  setPlan: (user: UserInfo, plan: Plan, invitedAs: "provider" | "client", invited: boolean) => any;
  getCanHandleServices: (userType: "proveedor" | "cliente",plan?: string) => boolean;
}

//STATE
const usePlanState = create<State>((set, get) => ({
  invitedAs: "client",
  plan: "FREE",
  invited: true,
  canCreateClients: false,
  canCreateProviders: false,
  canDeleteClients: false,
  canDeleteProviders: false,
  canHandleServices: {
    onClients: false,
    onProviders: false,
  },
  canValidateDocuments: {
    whenIsClient: false,
    whenIsProvider: false,
  },
  canUploadDocuments: {
    onExpedient: false,
    onCumplimiento: false,
  },
  canDeleteDocuments: false,
  canAccessVault: false,
  canAccesAdministation: false,
  canAccessRiesgos: false,
  canAccessReport: false,
  canAccessStripe: false,
  canConsultService: false,
  canConsultDocuments: false,
  canCreateAdmin: false,
  canEditCompany: false,
  canInvoicePayments: false,
  canHandlePlan: false,
  canShowHomeStages: false,
  canUploadUpdateContract: false,
  canValidateRequirementsDocs: false,
  getCanHandleServices: getCanHandleServices(set, get),
  setPlan: handleSetPlan(set, get),
}));

export default usePlanState;

//FUNCTIONS
function handleSetPlan(set: NamedSet<State>, get: GetState<State>) {
  return async (user: UserInfo, plan: Plan, invitedAs: "provider" | "client", invited: boolean) => {
    console.log("============================");
    console.log("===========kivit================");

    console.log("v3.0.G--- - Plan: " + plan + " Type: " + invitedAs + " Roles: [" + user.roles + "]");
    console.log("============================");

    const userRoles = user.roles?.map((role) => role.toLowerCase()) || [];
    const hasRoleAdmin = userRoles?.includes("admin");
    const hasRoleReviewer = userRoles?.includes("reviewer") || userRoles?.includes("revisor");
    //const hasRoleReader = userRoles?.includes("reader") || userRoles?.includes("lector");
    console.log("============================");
    console.log("hasRoleAdmin " + hasRoleReviewer);
    console.log("hasRoleReviewer " + hasRoleReviewer);
    console.log("invitedAs " + invitedAs);

    console.log("============================");
    if (plan === "PREMIUM") {
      plan = "CORPORATIVO";
    }
    const isProv = invitedAs === "provider";
    let newState: Partial<State>;
    switch (plan) {
      case "PYME":
        newState = {
          canCreateClients: isProv && hasRoleAdmin,
          canCreateProviders: false,
          canDeleteClients: isProv && hasRoleAdmin,
          canDeleteProviders: false,
          canHandleServices: {
            onClients: isProv && hasRoleAdmin,
            onProviders: false,
          },
          canValidateDocuments: { whenIsClient: false, whenIsProvider: false },
          canUploadDocuments: {
            onExpedient: isProv && (hasRoleAdmin || hasRoleReviewer),
            onCumplimiento: isProv && (hasRoleAdmin || hasRoleReviewer),
          },
          canDeleteDocuments: isProv && (hasRoleAdmin || hasRoleReviewer),
          canAccessVault: isProv,
          canAccesAdministation: hasRoleAdmin,
          canAccessRiesgos: false,
          canAccessReport: isProv,
          canAccessStripe: isProv,
          canConsultService: true,
          canConsultDocuments: true,
          canCreateAdmin: true,
          canEditCompany: true,
          canInvoicePayments: isProv,
          canHandlePlan: isProv,
          canShowHomeStages: isProv,
          canUploadUpdateContract: isProv,
          invitedAs,
          plan,
          invited,
        };
        break;
      case "CORPORATIVO":
        newState = {
          canCreateClients: hasRoleAdmin,
          canCreateProviders: hasRoleAdmin,
          canDeleteClients: hasRoleAdmin,
          canDeleteProviders: hasRoleAdmin,
          canHandleServices: { onClients: hasRoleAdmin, onProviders: hasRoleAdmin },
          canValidateDocuments: { whenIsClient: hasRoleAdmin || hasRoleReviewer, whenIsProvider: false },
          canUploadDocuments: {
            onExpedient: hasRoleAdmin || hasRoleReviewer,
            onCumplimiento: hasRoleAdmin || hasRoleReviewer,
          },
          canDeleteDocuments: hasRoleAdmin || hasRoleReviewer,
          canAccessVault: true,
          canAccesAdministation: hasRoleAdmin,
          canAccessRiesgos: !invited,
          canAccessReport: true,
          canAccessStripe: true,
          canConsultService: true,
          canConsultDocuments: true,
          canCreateAdmin: true,
          canEditCompany: true,
          canInvoicePayments: true,
          canHandlePlan: false,
          canShowHomeStages: true,
          canUploadUpdateContract: true,
          invitedAs,
          plan,
          invited,
        };
        break;
      default:
      case "FREE":
      case "TEMP":
        newState = {
          canCreateClients: isProv && hasRoleAdmin,
          canCreateProviders: false,
          canDeleteClients: isProv && hasRoleAdmin,
          canDeleteProviders: false,
          canHandleServices: {
            onClients: isProv && hasRoleAdmin,
            onProviders: false,
          },
          canValidateDocuments: { whenIsClient: false, whenIsProvider: false },
          canUploadDocuments: {
            onExpedient: isProv && (hasRoleAdmin || hasRoleReviewer),
            onCumplimiento: isProv && (hasRoleAdmin || hasRoleReviewer),
          },
          canDeleteDocuments: isProv && (hasRoleAdmin || hasRoleReviewer),
          canAccessVault: isProv,
          canAccesAdministation: hasRoleAdmin,
          canAccessRiesgos: false,
          canAccessReport: isProv,
          canAccessStripe: isProv,
          canConsultService: true,
          canConsultDocuments: true,
          canCreateAdmin: true,
          canEditCompany: true,
          canInvoicePayments: isProv,
          canHandlePlan: isProv,
          canShowHomeStages: isProv,
          canUploadUpdateContract: isProv,
          invitedAs,
          plan,
          invited,
        };
        break;
    }

    set((state) => ({ ...state, ...newState }));
  };
}

function getCanHandleServices(set: NamedSet<State>, get: GetState<State>) {
  return (userType: "proveedor" | "cliente", plan? : string) => {
    const { onClients, onProviders } = get().canHandleServices;
    return (userType === "cliente" && false) 
    || (userType === "proveedor" && onProviders 
    || userType=== 'cliente' && plan === 'CORPORATIVO' && onProviders
   // || userType=== 'cliente' && plan === 'CORPORATIVO' && onClients
   );
  };
}
