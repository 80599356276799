import { useEffect, useMemo, useState } from "react";
import { Grid } from "@material-ui/core";
import DashboardUsers from "../components/dashboard-home/dashboard-users/DashboardUsers";
import { CompaniesService } from "../core/services/companies.service";
import axios from "axios";
import useGlobalState from "../store/globalState";
import useDashboardUsersState from "../store/dashboardUsersState";
import useDashboardState from "../store/dashboardState";
import { AlertOld } from "../components/general/AlertOld";
import DashboardUsersProvider from "../components/dashboard-home/generadores/DashboardUsersClient";
const DashboardHome = () => {
  const { handleOpenUserDialog, clients, setClients, setIsLoadingCollaborator, isLoadingCollaborator } = useDashboardUsersState();
  const { infoAlert, handleCloseInfoAlert } = useDashboardState();

  const { mainCompany, setAlertInfo, typeService } = useGlobalState();
  const [filteredClients, setFilteredClients] = useState<CompanyCollaborator[] | null>(null);
  const renderClients = useMemo(() => (filteredClients ? filteredClients : clients), [filteredClients, clients]);

  useEffect(() => {
    if (mainCompany?.id) {
      findAllClients();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainCompany?.id]);

  const findAllClients = async () => {
    setIsLoadingCollaborator(true);
    if (!mainCompany?.id) return;
    try {
      const data = await CompaniesService.getInstance().getCompanyClients(mainCompany?.id);
      //@ts-ignore
      setClients([...data?.clients, ...data?.pendingCollaborations]);
    } finally {
      setIsLoadingCollaborator(false);
    }
  };

  const handleSearch = (value: string) => {
    if (value) {
      const parseValue = value.toLowerCase();
      setFilteredClients(
        clients.filter((client) => {
          return (
            client?.companySelf?.name?.toLowerCase().includes(parseValue) || client?.companySelf?.rfc?.toLowerCase().includes(parseValue)
          );
        })
      );
    } else {
      setFilteredClients(null);
    }
  };

  const handleAnswerInvitation = async (processId: string, answer: boolean) => {
    setIsLoadingCollaborator(true);
    try {
      if (!processId) throw new Error("Hay un problema con los datos de la invitación");
      await CompaniesService.getInstance().asnwerCollaboration(processId, answer);
      await findAllClients();
    } catch (error) {
      let message = "Hubo un problema al responder la invitación";
      if (axios.isAxiosError(error)) {
        message = error.message === "Network Error" ? "Error al conectar con el servidor" : error.message;
      }
      setAlertInfo({
        message,
        type: "error",
      });
    } finally {
      setIsLoadingCollaborator(false);
    }
  };

  return (
    <Grid container>
      {typeService === 'generators' ?
        <DashboardUsersProvider
          title="Clientes"
          hasServices
          filters={userTypeFilters}
          userType="cliente"
          onAdd={() => handleOpenUserDialog({ formType: "user", isNewUser: false })}
          onSearch={handleSearch}
          users={renderClients}
          loading={isLoadingCollaborator}
          onAnswerInvitation={handleAnswerInvitation}
        />
        :
        <DashboardUsers
          title="Clientes"
          hasServices
          filters={userTypeFilters}
          userType="cliente"
          onAdd={() => handleOpenUserDialog({ formType: "user", isNewUser: false })}
          onSearch={handleSearch}
          users={renderClients}
          loading={isLoadingCollaborator}
          onAnswerInvitation={handleAnswerInvitation}
        />
      }


      <AlertOld open={infoAlert.show} title={infoAlert.title} type={infoAlert.type} onConfirm={handleCloseInfoAlert} />
    </Grid>
  );
};

const userTypeFilters: UserFilter[] = [
  {
    label: "Personas Físicas",
    type: "fisica",
  },
  {
    label: "Personas Morales",
    type: "moral",
  },
];

export default DashboardHome;