import { useEffect, useState } from "react";
import { Box, Button, Chip, ClickAwayListener, Grid, IconButton, InputAdornment, Paper, Popper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, makeStyles } from "@material-ui/core";
import { Search, FilterList, FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from "@material-ui/icons";
import { GeneradoresServices } from "../../../core/services/generadores.service";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import useGlobalState from "../../../store/globalState";
import useGeneralFormCompleteState from "../../../store/generadores/generalFormState";
import useGeolocationFormState from "../../../store/generadores/geolocationFormState";
import usePhotographicFormState from "../../../store/generadores/photographicFormState";
import FormSelector from "./FormSelector";
import { defaultGeolocationBody, defaultPhotographicBody } from "../../../store/generadores/defaultForms";
import useUiState from "../../../store/uiState";
import useGeneradoresStagesState from "../../../store/generadores/generadoresStagesState";
import useCustomToast from "../../../core/hooks/useCustomToast";
import PdfModal from "../../UI/PdfViewer";
import { axiosOrkestadocRequest } from "../../../configs/axios.config";
import DocumentGeneratorInfoModal from "../../UI/DocumentGeneratorInfoModal";
import ReportGenerator from "./ReportGenerator";
import AddIcon from '@material-ui/icons/Add';
import AddIconV2 from '@material-ui/icons/AddCircleOutline';

const HomeGenerator = () => {
    const classes = useStyles();
    const toast = useCustomToast();
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [showForms, setShowForms] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const { mainCompany } = useGlobalState();
    const [data, setData] = useState([]);
    const setIdForm = useGeneralFormCompleteState((state) => state.setIdForm);
    const setformGeneratedCode = useGeneralFormCompleteState((state) => state.setformGeneratedCode);
    const updateGeneralFormBody = useGeneralFormCompleteState((state) => state.updateGeneralForm);
    const updateGeneralNextBody = useGeneralFormCompleteState((state) => state.updateGeneralNextForm);
    const updateGeolocationBody = useGeolocationFormState((state) => state.updateGeolocationForm);
    const updatePhotographicBody = usePhotographicFormState((state) => state.updatePhotographicForm);
    const cleanGeneralForms = useGeneralFormCompleteState((state) => state.cleanForms);
    const setShowGeneratorsList = useGeneralFormCompleteState((state) => state.setShowGeneratorsList);
    const setProviderLogoId = useGeneralFormCompleteState((state) => state.setProviderLogoId);
    const setAttachedFileId = useGeneralFormCompleteState((state) => state.setAttachedFileId);
    const { currentService } = useGeneradoresStagesState();
    const idProvider = mainCompany?.id;
    const idCompany = mainCompany?.clients !== null ? mainCompany?.clients[0]?.clientId : '';
    const { setShowGenericLoader } = useUiState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [rowClicked, setRowClicked] = useState<any>();
    const [pdfView, setPdfView] = useState(false);
    const [dataUrl, setDataUrl] = useState('');
    const [viewDocumentInfo, setViewDocumentInfo] = useState(false);
    const [documentInfo, setDocumentInfo] = useState(null);

    const handleClick = (event, row) => {
        setRowClicked(row);
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setRowClicked(null);
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const bodyGeolocation = {
        date: "",
        address: "",
        code: "",
        coordinates: "",
        folioTracker: "",
    };


    /**
     *     useEffect(() => {
            console.log(currentService);
            GeneradoresServices.getInstance().getGeneradoresByProviderAndService(idProvider, currentService.id).then((data) => {//obtener todos los generadores por el providerId
                setTimeout(() => {
                    setShowGenericLoader(false);
                }, 1000);
                setData(data.respuesta);
            }).catch((error) => {
                setShowGenericLoader(false);
            });
        }, []);
     */
    useEffect(() => {
        if (currentService && currentService.id) {
            GeneradoresServices.getInstance().getGeneradoresByProviderAndService(idProvider, currentService.id)
                .then((data) => {
                    setShowGenericLoader(false);

                    setData(data.respuesta);
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    setShowGenericLoader(false);

                });
        }
    }, [idProvider, currentService]);

    useEffect(() => {
        if (!showForms) {//volvemos a cargar los forms cuando cerramos la vista de los formularios
            GeneradoresServices.getInstance().getGeneradoresByProviderAndService(idProvider, currentService.id).then((data) => {
                setData(data.respuesta);
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [showForms]);

    const formatNumber = (number) => {
        return new Intl.NumberFormat('es-MX', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(number);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const disableGenerator = async (id: string) => {
        try {
            setShowGenericLoader(true);
            await GeneradoresServices.getInstance().disableGenerator(id).then(async () => {
                await GeneradoresServices.getInstance().getGeneradoresByProviderAndService(idProvider, currentService.id).then((data) => {
                    setData(data.respuesta);
                    setShowGenericLoader(false);
                })
            });
            toast.success("Generador deshabilitado.");
        } catch (error) {
            toast.error("Intente de nuevo más tarde.");
            setShowGenericLoader(false);
        }
    };

    const [columnFilters, setColumnFilters] = useState({
        providerName: '',
        generatedCode: '',
        startDate: '',
        endDate: '',
        total: '',
        city: '',
        status: '',
        statusPago: '',
    });

    const handleColumnFilterChange = (column, value) => {
        setColumnFilters({
            ...columnFilters,
            [column]: value
        });
    };

    const createForm = async () => {
        setShowGenericLoader(true);
        await GeneradoresServices.getInstance().createFormGenerador(idCompany, currentService.id, idProvider).then(async (data) => {
            setformGeneratedCode(data.respuesta.generatedCode);
            setIdForm(data.respuesta.id);
            cleanGeneralForms();
            setProviderLogoId('');
            updateGeolocationBody(defaultGeolocationBody);
            updatePhotographicBody(defaultPhotographicBody);

            await GeneradoresServices.getInstance().patchGeolocationForm(data.respuesta.id, bodyGeolocation);
            await GeneradoresServices.getInstance().patchPhotographicForm(data.respuesta.id, defaultPhotographicBody);
            setTimeout(() => {
                setShowGenericLoader(false);
                setShowForms(true);
            }, 1000);
        }).catch((error) => {
            setShowGenericLoader(false);
            console.log(error);
        });
    };

    const viewForm = async (id: string) => {
        await GeneradoresServices.getInstance().getGeneradorById(id).then(async (data) => {
            setformGeneratedCode(data.respuesta.generatedCode);
            setIdForm(data.respuesta.id);
            setProviderLogoId(data.respuesta.providerLogoId);
            if (data.respuesta.attachedFile) {
                setAttachedFileId(data.respuesta.attachedFile.idFile);
            }
            if (data?.respuesta?.general) {
                updateGeneralFormBody(data?.respuesta?.general);
                updateGeneralNextBody(data?.respuesta?.general?.supervisor)
            } else {
                cleanGeneralForms();
            }
            if (data?.respuesta?.geolocation) {
                updateGeolocationBody(data?.respuesta?.geolocation);
            } else {
                updateGeolocationBody(defaultGeolocationBody);
                await GeneradoresServices.getInstance().patchGeolocationForm(data.respuesta.id, bodyGeolocation);
            }
            if (data?.respuesta?.photographic) {
                updatePhotographicBody(data?.respuesta?.photographic);
            } else {
                updatePhotographicBody(defaultPhotographicBody);
                await GeneradoresServices.getInstance().patchPhotographicForm(data.respuesta.id, defaultPhotographicBody);
            }
            setShowForms(true);
        }).catch((error) => {
            console.log(error);
        });
    };

    const tagStyle = (tag: string, type: string) => {
        let styles;
        let label;
        if ((tag === 'APPROVED') || (tag === 'PAID')) {
            styles = {//green palette
                color: '#22543D',
                backgroundColor: '#C6F6D5'
            }
            label = (tag === 'APPROVED' ? 'Aprobado' : 'Pagado');
        } else if ((tag === 'REJECTED') || (tag === 'DISABLED')) {
            styles = {//red palette
                color: '#822727',
                backgroundColor: '#FED7D7'
            }
            label = (tag === 'REJECTED' ? 'Rechazado' : 'Deshabilitado');
        } else if ((tag === 'REVISION') || (tag === 'DRAFT')) {
            styles = {//yellow palette
                color: '#7B341E',
                backgroundColor: '#FEEBCB'
            }
            label = (tag === 'REVISION' ? 'Bajo Revisión' : 'En proceso');
        } else {
            styles = {//grey palette
                color: '#4A4A4A',
                backgroundColor: '#F3F3F3'
            }
            label = 'Pendiente de pago';
        }
        if (type === 'style') {
            return styles;
        } else {
            return label;
        }
    };

    const viewGeneratorInfoFromOrkestaDoc = async (biddingId: string) => {
        if (biddingId === null) {
            toast.warnign("Verifique con su administrador que el documento ya haya pasado la revisión.");
        } else {
            setShowGenericLoader(true);
            try {
                await GeneradoresServices.getInstance().getDocumentGeneratorData(biddingId).then((data) => {
                    if (data.respuesta === null) {
                        toast.warnign("Actualmente, no hay información disponible del generador.");
                        setShowGenericLoader(false);
                    } else {
                        setDocumentInfo(data.respuesta);
                        setViewDocumentInfo(true);
                        setShowGenericLoader(false);
                    }
                });
            } catch (error) {
                setShowGenericLoader(false);
                toast.warnign("Verifique con su administrador que el documento ya haya pasado la revisión.");
            }
        }
    };

    const viewPDF = async (idPdfFile: string) => {
        setShowGenericLoader(true);
        try {
            await GeneradoresServices.getInstance().getImage(idPdfFile).then((data) => {
                const base64 = data.file;
                const binaryString = atob(base64);
                const len = binaryString.length;
                const bytes = new Uint8Array(len);
                for (let i = 0; i < len; i++) {
                    bytes[i] = binaryString.charCodeAt(i);
                }
                const blob = new Blob([bytes], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                setDataUrl(url);
                setPdfView(true);
                setShowGenericLoader(false);
            });
        } catch (error) {
            toast.error("Ha ocurrido un error al generar el PDF");
            setShowGenericLoader(false);
        }
    };

    const getValidString = (value: string | undefined | null, defaultValue: string = ''): string => {
        return typeof value === 'string' ? value.toLowerCase() : defaultValue;
    };

    const filteredData = data.filter(item => {
        return (
            getValidString(item.generatedCode).includes(getValidString(searchTerm)) &&
            getValidString(item.providerName).includes(getValidString(columnFilters.providerName)) &&
            getValidString(item.generatedCode).includes(getValidString(columnFilters.generatedCode)) &&
            getValidString(item.startDate).includes(getValidString(columnFilters.startDate)) &&
            getValidString(item.endDate).includes(getValidString(columnFilters.endDate)) &&
            getValidString(item.total).includes(getValidString(columnFilters.total)) &&
            getValidString(item.city).includes(getValidString(columnFilters.city)) &&
            getValidString(item.status).includes(getValidString(columnFilters.status)) &&
            getValidString(item.statusPago).includes(getValidString(columnFilters.statusPago))
        );
    });


    const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredData.length - page * rowsPerPage);

    console.log("filteredData", filteredData)
    return (
        <>
            {showForms ? (
                <FormSelector setShowForms={setShowForms} />
            ) : pdfView ? (
                <PdfModal open={pdfView} handleClose={() => { setPdfView(false); }} pdfBlobUrl={dataUrl}></PdfModal>
            ) : (
                <Box>
                    <Box
                        bgcolor="white"
                        borderRadius="20px"
                        className="animate__animated animate__backInUp"
                        color="text.primary"
                        display="flex"
                        flexDirection="column"
                        style={{
                            width: '95%',
                            padding: '40px',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                    >
                        <div className={classes.container}>
                            <div className={classes.header}>
                                <Box display="flex" justifyContent="space-between" alignItems="flex-end" style={{ gap: "1rem", marginTop: '-5%' }}>
                                    <Grid item>
                                        <Button size="small" startIcon={<ChevronLeftIcon />} variant="contained" onClick={() => setShowGeneratorsList()}>
                                            Regresar
                                        </Button>
                                    </Grid>
                                </Box>
                            </div>
                            <div className={classes.searchContainer} style={{ display: 'flex', alignItems: 'center' }}>
                                <h3 style={{ marginRight: '10px' }}>
                                    Generadores
                                </h3>
                                <TextField
                                    className={classes.searchInput}
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    placeholder='Buscar'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <Search />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor: 'rgba(118, 120, 237, 1)',
                                            color: 'rgba(255, 255, 255, 1)',
                                            marginRight: '5px', // Añadir un pequeño margen a la derecha
                                        }}
                                        onClick={() => {
                                            createForm();
                                        }}
                                        startIcon={<AddIconV2 />}
                                    >
                                        Agregar formulario
                                    </Button>
                                    <ReportGenerator filteredData={filteredData} />
                                </div>
                            </div>
                            {data.length > 0 ? (
                                <>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} aria-label="custom table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <div className={classes.labelContainer}>
                                                            <label className={classes.labelCol} htmlFor="providerName">Proveedor</label>
                                                            <TextField
                                                                fullWidth
                                                                id="providerName"
                                                                value={columnFilters.providerName}
                                                                onChange={(e) => handleColumnFilterChange('providerName', e.target.value)}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <FilterList />
                                                                        </InputAdornment>
                                                                    ),
                                                                }} />
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className={classes.labelContainer}>
                                                            <label className={classes.labelCol} htmlFor="generatedCode">ID del generador</label>
                                                            <TextField
                                                                fullWidth
                                                                id="id"
                                                                value={columnFilters.generatedCode}
                                                                onChange={(e) => handleColumnFilterChange('generatedCode', e.target.value)}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <FilterList />
                                                                        </InputAdornment>
                                                                    ),
                                                                }} />
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className={classes.labelContainer}>
                                                            <label className={classes.labelCol} htmlFor="startDate">Inicio</label>
                                                            <TextField
                                                                fullWidth
                                                                id="startDate"
                                                                value={columnFilters.startDate}
                                                                onChange={(e) => handleColumnFilterChange('startDate', e.target.value)}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <FilterList />
                                                                        </InputAdornment>
                                                                    ),
                                                                }} />
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className={classes.labelContainer}>
                                                            <label className={classes.labelCol} htmlFor="endDate">Fin</label>
                                                            <TextField
                                                                fullWidth
                                                                id="endDate"
                                                                value={columnFilters.endDate}
                                                                onChange={(e) => handleColumnFilterChange('endDate', e.target.value)}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <FilterList />
                                                                        </InputAdornment>
                                                                    ),
                                                                }} />
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className={classes.labelContainer}>
                                                            <label className={classes.labelCol} htmlFor="total">Importe total</label>
                                                            <TextField
                                                                fullWidth
                                                                id="total"
                                                                value={columnFilters.total}
                                                                onChange={(e) => handleColumnFilterChange('total', e.target.value)}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <FilterList />
                                                                        </InputAdornment>
                                                                    ),
                                                                }} />
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className={classes.labelContainer}>
                                                            <label className={classes.labelCol} htmlFor="city">Ciudad</label>
                                                            <TextField
                                                                fullWidth
                                                                id="city"
                                                                value={columnFilters.city}
                                                                onChange={(e) => handleColumnFilterChange('city', e.target.value)}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <FilterList />
                                                                        </InputAdornment>
                                                                    ),
                                                                }} />
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className={classes.labelContainer}>
                                                            <label className={classes.labelCol} htmlFor="status">Estatus</label>
                                                            <TextField
                                                                fullWidth
                                                                id="status"
                                                                value={columnFilters.status}
                                                                onChange={(e) => handleColumnFilterChange('status', e.target.value)}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <FilterList />
                                                                        </InputAdornment>
                                                                    ),
                                                                }} />
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className={classes.labelContainer}>
                                                            <label className={classes.labelCol} htmlFor="statusPago">Estatus de pago</label>
                                                            <TextField
                                                                fullWidth
                                                                id="statusPago"
                                                                value={columnFilters.statusPago}
                                                                onChange={(e) => handleColumnFilterChange('statusPago', e.target.value)}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <FilterList />
                                                                        </InputAdornment>
                                                                    ),
                                                                }} />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {(rowsPerPage > 0
                                                    ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    : filteredData
                                                ).map((row) => (
                                                    <TableRow key={row.id} >
                                                        <TableCell>{row.providerName}</TableCell>
                                                        <TableCell>{row.generatedCode}</TableCell>
                                                        <TableCell>{row.startDate}</TableCell>
                                                        <TableCell>{row.endDate}</TableCell>
                                                        <TableCell>{row?.total === null || row?.total === undefined ? '' : '$' + formatNumber(row.total)}</TableCell>
                                                        <TableCell>{row.city}</TableCell>
                                                        <TableCell className={classes.badgeContainer}>
                                                            <Chip
                                                                label={tagStyle(row.status, 'label')}
                                                                style={tagStyle(row.status, 'style')}
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.badgeContainer}>
                                                            <Chip
                                                                label={tagStyle(row.statusPago, 'label')}
                                                                style={tagStyle(row.statusPago, 'style')} />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MenuIcon
                                                                color="primary"
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={(e) => {
                                                                    handleClick(e, row);
                                                                }}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                <Popper id={id} open={open} anchorEl={anchorEl} placement="left-start" className={classes.popper}>
                                                    <ClickAwayListener onClickAway={handleClickAway}>
                                                        <Box className={classes.popperContent}>
                                                            <Box
                                                                onClick={() => {
                                                                    if (rowClicked?.status === "DRAFT") {
                                                                        setIdForm(rowClicked.id);
                                                                        viewForm(rowClicked.id);
                                                                        setAnchorEl(null);
                                                                    }
                                                                }}
                                                            >
                                                                <Chip
                                                                    disabled={rowClicked?.status === "DRAFT" ? false : true}
                                                                    clickable={true}
                                                                    label={"Editar"}
                                                                    style={{ cursor: 'pointer', color: '#0066CC', backgroundColor: '#87CEFA', margin: '3px', width: '100%' }}
                                                                />
                                                            </Box>
                                                            <Box
                                                                onClick={async () => {
                                                                    if (rowClicked?.pdfFileId !== null) {
                                                                        await viewPDF(rowClicked.pdfFileId);
                                                                        setAnchorEl(null);
                                                                    }
                                                                }}
                                                            >
                                                                <Chip
                                                                    disabled={rowClicked?.pdfFileId !== null ? false : true}
                                                                    clickable={true}
                                                                    label={"Ver Generador"}
                                                                    style={{ cursor: 'pointer', color: '#22543D', backgroundColor: '#C6F6D5', margin: '3px', width: '100%' }}
                                                                />
                                                            </Box>
                                                            <Box
                                                                onClick={async () => {
                                                                    if (rowClicked?.idBiddingDocumentGenerador !== null) {
                                                                        await viewGeneratorInfoFromOrkestaDoc(rowClicked.idBiddingDocumentGenerador);
                                                                        setAnchorEl(null);
                                                                    }
                                                                }}
                                                            >
                                                                <Chip
                                                                    disabled={rowClicked?.idBiddingDocumentGenerador !== null ? false : true}
                                                                    clickable={true}
                                                                    label={"Información"}
                                                                    style={{ cursor: 'pointer', color: '#7B341E', backgroundColor: '#FEEBCB', margin: '3px', width: '100%' }}
                                                                />
                                                            </Box>
                                                            <Box
                                                                onClick={async () => {
                                                                    if ((rowClicked?.status !== "DISABLED" && rowClicked?.status !== "REVISION" && rowClicked?.status !== "APPROVED")) {
                                                                        await disableGenerator(rowClicked.id);
                                                                        setAnchorEl(null);
                                                                    }
                                                                }}
                                                            >
                                                                <Chip
                                                                    disabled={(rowClicked?.status === "APPROVED" || rowClicked?.status === "DISABLED" || rowClicked?.status === "REVISION") ? true : false}
                                                                    clickable={true}
                                                                    label={"Deshabilitar"}
                                                                    style={{ cursor: 'pointer', color: '#822727', backgroundColor: '#FED7D7', margin: '3px', width: '100%' }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </ClickAwayListener>
                                                </Popper>
                                                {emptyRows > 0 && (
                                                    <TableRow style={{ height: 53 * emptyRows }}>
                                                        <TableCell colSpan={5} />
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer><TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        labelRowsPerPage={'rows'}
                                        count={filteredData.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={() => (
                                            <div className={classes.paginatorButtons}>
                                                <IconButton onClick={() => handleChangePage(null, 0)} disabled={page === 0} aria-label="first page">
                                                    <FirstPage />
                                                </IconButton>
                                                <IconButton onClick={() => handleChangePage(null, page - 1)} disabled={page === 0} aria-label="previous page">
                                                    <KeyboardArrowLeft />
                                                </IconButton>
                                                <IconButton onClick={() => handleChangePage(null, page + 1)} disabled={page >= Math.ceil(filteredData.length / rowsPerPage) - 1} aria-label="next page">
                                                    <KeyboardArrowRight />
                                                </IconButton>
                                                <IconButton onClick={() => handleChangePage(null, Math.max(0, Math.ceil(filteredData.length / rowsPerPage) - 1))} disabled={page >= Math.ceil(filteredData.length / rowsPerPage) - 1} aria-label="last page">
                                                    <LastPage />
                                                </IconButton>
                                            </div>
                                        )} />
                                </>
                            ) : (
                                <h2>
                                    No hay datos suficientes
                                </h2>
                            )}
                        </div>
                    </Box>
                </Box>
            )}
            {viewDocumentInfo && (
                <DocumentGeneratorInfoModal open={viewDocumentInfo} handleClose={() => { setViewDocumentInfo(false); }} data={documentInfo} />
            )}
        </>
    );
};


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    searchContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '8px',
        marginBottom: '8px'
    },
    searchInput: {
        width: '300px',
    },
    container: {
        backgroundColor: '#fff',
        padding: '16px'
    },
    labelContainer: {
        textAlign: 'center'
    },
    labelCol: {
        color: theme.palette.primary.main,
        display: 'block',
    },
    badgeContainer: {
        textAlign: 'center'
    },
    badgeBg: {
        width: '120px',
    },
    paginatorButtons: {
        display: 'flex'
    },
    banner: {
        padding: "1%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "inherit",
        color: "white",
        background: "linear-gradient(107.74deg, rgba(35, 71, 173, 0.9) 0%, rgba(82, 198, 186, 0.9) 48.55%, rgba(118, 120, 237, 0.9) 99.17%)",
    },
    header: {
        width: '100%',
        display: 'flex',
    },
    popper: {
        zIndex: theme.zIndex.tooltip,
        borderRadius: '10px',
        boxShadow: theme.shadows[5],
    },
    popperContent: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        borderRadius: '10px', // Ensure content inside is also rounded
    },
}));

export default HomeGenerator;
