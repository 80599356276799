/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  makeStyles,
  Backdrop,
  CircularProgress,
  Select as MatSelect,
  FormControl,
  OutlinedInput,
  FormHelperText,
  Select,
  MenuItem,
} from "@material-ui/core";
import * as Yup from "yup";
import TextField from "../../inputs/inputs-v2/RenderTextFieldV2";
import { useFormik } from "formik";
import { GeneralFormInfoReqBody } from "../../../types/companies-service";
import useGeneralFormCompleteState from "../../../store/generadores/generalFormState";
import useGlobalState from "../../../store/globalState";
import useCustomToast from "../../../core/hooks/useCustomToast";
import { CompaniesService } from "../../../core/services/companies.service";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FormGeneral = ({goToNextForm }) => {
  const generalFormBody = useGeneralFormCompleteState((state) => state.generalFormBody);
  const updateGeneralFormBody = useGeneralFormCompleteState((state) => state.updateGeneralForm);
  const providerLogo = useGeneralFormCompleteState((state) => state.providerLogoId);
  const classes = useStyles();
  const toast = useCustomToast();

  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<any>(generalFormBody.startDate);
  const [endDate, setEndDate] = useState<any>(generalFormBody.endDate);
  const [servicesGenerator, setServiceGenerator] = useState([]);
  const [servicesSelected, setServices] = React.useState(generalFormBody.serviceOfferedIdList);

  const mainCompanyId = sessionStorage.getItem("companyId");
  const { mainCompany } = useGlobalState();
  const rfcProvider = mainCompany?.rfc;
  const nameProvider = mainCompany?.name;
  const externalId = mainCompany?.externalId !== null ? mainCompany?.externalId : '';
  const externalSapId = mainCompany?.externalSapId !== null ? mainCompany?.externalSapId : '';

  const handleSubmit = async (values: GeneralFormInfoReqBody) => {
    const body: GeneralFormInfoReqBody = {
      clientName: values.clientName, 
      csp: values.csp,
      idPlace: values.idPlace,
      cot: values.cot,
      cuenta: values.cuenta,
      serviceOfferedIdList: values.serviceOfferedIdList,
      startDate: values.startDate,
      endDate: values.endDate,
      idCode: values.idCode,
      name: values.name,
      idSupplier: values.idSupplier,
      idSap: values.idSap,
      district: values.district,
      cluster: values.cluster,
      city: values.city,
      state: values.state,
      address: values.address,
      projectName: values.projectName,
      area: values.area,
      assignment: values.assignment,
      servicesList: generalFormBody.servicesList,
      businessUnit: values.businessUnit
    };

    updateGeneralFormBody(body);
    if (providerLogo === '' || providerLogo === null) {
      toast.error("Guarde el logo del proveedor para continuar.");
    } else {
      toast.success("Información guardada con éxito.");
      goToNextForm('FormGeneralNext');
    }
    
  };

  const formik = useFormik<GeneralFormInfoReqBody>({
    initialValues: {
      clientName: generalFormBody.clientName,
      csp: generalFormBody.csp,
      idPlace: generalFormBody.idPlace,
      cot: generalFormBody.cot,
      cuenta: generalFormBody.cuenta,
      serviceOfferedIdList: generalFormBody.serviceOfferedIdList,
      startDate: startDate,
      endDate: endDate,
      idCode: rfcProvider,
      name: nameProvider,
      idSupplier: externalId,
      idSap: externalSapId,
      district: generalFormBody.district,
      cluster: generalFormBody.cluster,
      city: generalFormBody.city,
      state: generalFormBody.state,
      address: generalFormBody.address,
      projectName: generalFormBody.projectName,
      area: generalFormBody.area,
      assignment: generalFormBody.assignment,
      servicesList: generalFormBody.servicesList,
      businessUnit: generalFormBody.businessUnit
    },
    onSubmit: handleSubmit,
    validationSchema: Yup.object({
      clientName: Yup.string().required("Campo requerido"),
      csp: Yup.string().required("Campo requerido"),
      cot: Yup.string().required("Campo requerido"),
      cuenta: Yup.string().required("Campo requerido"),
      serviceOfferedIdList: Yup.array(Yup.string().min(2, "Seleccione almenos 1 servicio")).required("Campo requerido"),
      startDate: Yup.date().required("Campo requerido"),
      endDate: Yup.date().required("Campo requerido"),
      idSupplier: Yup.string().required("Campo requerido"),
      idSap: Yup.string().required("Campo requerido"),
      city: Yup.string().required("Campo requerido"),
      state: Yup.string().required("Campo requerido"),
      address: Yup.string().required("Campo requerido"),
      projectName: Yup.string().required("Campo requerido").nullable(),
      area: Yup.string().required("Campo requerido").nullable(),
      assignment: Yup.string().required("Campo requerido").nullable(),
      businessUnit: Yup.string().required("Campo requerido").nullable(),
    }),
  });

  const fetchServiceOffered = async (companyId: string) => {
    try {
        const servicesG = await CompaniesService.getInstance().getServiceOffered(companyId);
        console.log("res servicesG", servicesG.respuesta); // Muestra la respuesta en la consola

        const serviceDescriptions = servicesG.respuesta.map(service => service.description);

        setServiceGenerator(serviceDescriptions); // Guarda la respuesta en el estado
    } catch (error) {
        console.error("Error fetching service offered:", error); // Maneja el error
    }
  };

  useEffect(() => {
    console.log();
    fetchServiceOffered(mainCompanyId);
  }, []);

  const handleChange = (event) => {
    const values: string[] = event.target.value;
    if (values.length === 0) {
      setServices([" "]);
    } else {
      setServices(values.filter((e) => e !== " "));
    }
  };

  useEffect(() => {
    formik.setFieldValue("serviceOfferedIdList", servicesSelected);
  }, [servicesSelected]);

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <Grid container style={{width: '100%'}}>
        <Grid item md={12}>
          <div className={classes.subContainer}>
            <div className={classes.inputDoubleRow}>
              <TextField
                label="Nombre del cliente"
                size="small"
                error={formik.errors.clientName}
                onChange={formik.handleChange}
                touched={formik.touched.clientName}
                value={formik.values.clientName}
                placeholder="Nombre del cliente*"
                name="clientName"
              />

              <TextField
                size="small"
                error={formik.errors.csp}
                onChange={formik.handleChange}
                touched={formik.touched.csp}
                value={formik.values.csp}
                label="CSP"
                placeholder="CSP *"
                name="csp"
              />
            </div>

            <TextField
              size="small"
              error={formik.errors.idPlace}
              onChange={formik.handleChange}
              touched={formik.touched.idPlace}
              value={formik.values.idPlace}
              placeholder="ID del lugar"
              label="ID del lugar"
              name="idPlace"
            />

            <div className={classes.inputDoubleRow}>
              <TextField
                size="small"
                error={formik.errors.cot}
                onChange={formik.handleChange}
                touched={formik.touched.cot}
                value={formik.values.cot}
                placeholder="COT"
                label="COT"
                name="cot"
              />

              <TextField
                size="small"
                error={formik.errors.cuenta}
                onChange={formik.handleChange}
                touched={formik.touched.cuenta}
                value={formik.values.cuenta}
                placeholder="Cuenta"
                label="Cuenta"
                name="cuenta"
              />
            </div>

            <TextField
                size="small"
                error={formik.errors.projectName}
                onChange={formik.handleChange}
                touched={formik.touched.projectName}
                value={formik.values.projectName}
                placeholder="Nombre del proyecto"
                label="Nombre del proyecto"
                name="projectName"
              />

            <FormControl variant="outlined" fullWidth margin="dense" error={!!formik.touched.serviceOfferedIdList && !!formik.errors.serviceOfferedIdList}>
              <Typography variant="body2">{"Nombre del servicio"}</Typography>
              <MatSelect
                name="serviceOfferedIdList"
                value={formik.values.serviceOfferedIdList}
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" />}
                renderValue={(selected: any) => {
                  return (
                    <div>
                      {selected[0] === "" && <span>Servicios ofrecidos (uno o varios) *</span>}
                      {selected.map(
                        (value: string, idx: number) =>
                          !!value &&
                          value !== "" && (
                            <span key={value}>
                              {idx > 0 && ", "}
                              {value.substr(0, 24)} {value.length > 24 && "..."}
                            </span>
                          )
                      )}
                    </div>
                  );
                }}
                MenuProps={MenuProps}
              >
                {servicesGenerator.map((name) => (
                  <option
                    key={name}
                    value={name}
                    style={{
                      backgroundColor: `${servicesSelected.includes(name) ? "#3297FD" : "unset"}`,
                      color: `${servicesSelected.includes(name) ? "#fff" : "unset"}`,
                      cursor: "pointer",
                    }}
                  >
                    {name}
                  </option>
                ))}
              </MatSelect>
              <FormHelperText>{!!formik.touched.serviceOfferedIdList && formik.errors.serviceOfferedIdList}</FormHelperText>
            </FormControl>

            <div className={classes.inputDoubleRow}>
              <TextField
                size="small"
                label="Fecha de inicio"
                error={formik.errors.startDate}
                touched={formik.touched.startDate}
                value={startDate?.split("T")[0]}
                onChange={(e) => {
                  const date = new Date(e.target.value);
                  date.setHours(24);
                  setStartDate(date?.toISOString().split("T")[0]);
                  formik.setFieldValue('startDate', date?.toISOString().split("T")[0]);
                }}
                name="startDate"
                type="date"
                minDate="2024-05-01"
              />

              <TextField
                size="small"
                label="Fecha de fin"
                error={formik.errors.endDate}
                touched={formik.touched.endDate}
                value={endDate?.split("T")[0]}
                onChange={(e) => {
                  const date = new Date(e.target.value);
                  date.setHours(24);
                  setEndDate(date?.toISOString().split("T")[0]);
                  formik.setFieldValue('endDate', date?.toISOString().split("T")[0]);
                }}
                name="endDate"
                type="date"
                minDate={startDate?.split("T")[0]}
              />
            </div>

              <TextField
                size="small"
                error={formik.errors.name}
                onChange={formik.handleChange}
                touched={formik.touched.name}
                value={formik.values.name}
                placeholder="Razón social"
                label="Razón social"
                name="name"
                disabled={true}
              />

              <div className={classes.inputTripleRow}>
                <TextField
                  size="small"
                  error={formik.errors.idCode}
                  onChange={formik.handleChange}
                  touched={formik.touched.idCode}
                  value={formik.values.idCode}
                  placeholder="RFC *"
                  label="RFC"
                  name="idCode"
                  disabled={true}
                />

                <TextField
                  size="small"
                  error={formik.errors.idSupplier}
                  onChange={formik.handleChange}
                  touched={formik.touched.idSupplier}
                  value={formik.values.idSupplier}
                  placeholder="ID del proveedor *"
                  label="ID del proveedor"
                  name="idSupplier"
                  disabled={true}
                />

                <TextField
                  size="small"
                  error={formik.errors.idSap}
                  onChange={formik.handleChange}
                  touched={formik.touched.idSap}
                  value={formik.values.idSap}
                  placeholder="ID del proveedor SAP *"
                  label="ID del proveedor SAP"
                  name="idSap"
                  disabled={true}
                />
              </div>

            <div className={classes.inputDoubleRow}>
              <TextField
                size="small"
                error={formik.errors.district}
                onChange={formik.handleChange}
                touched={formik.touched.district}
                value={formik.values.district}
                placeholder="Distrito *"
                label="Distrito"
                name="district"
                type="text"
              />

              <TextField
                size="small"
                error={formik.errors.cluster}
                onChange={formik.handleChange}
                touched={formik.touched.cluster}
                value={formik.values.cluster}
                placeholder="Cluster *"
                label="Cluster"
                name="cluster"
              />
            </div>

            <div className={classes.inputDoubleRow}>
              <TextField
                size="small"
                error={formik.errors.city}
                onChange={formik.handleChange}
                touched={formik.touched.city}
                value={formik.values.city}
                placeholder="Ciudad *"
                label="Ciudad"
                name="city"
                type="text"
              />

              <TextField
                size="small"
                error={formik.errors.state}
                onChange={formik.handleChange}
                touched={formik.touched.state}
                value={formik.values.state}
                placeholder="Estado *"
                label="Estado"
                name="state"
                type="text"
              />
            </div>

            <TextField
              size="small"
              error={formik.errors.address}
              onChange={formik.handleChange}
              touched={formik.touched.address}
              value={formik.values.address}
              placeholder="Dirección del lugar donde se lleva a cabo el trabajo *"
              label="Dirección del lugar donde se lleva a cabo el trabajo"
              name="address"
              type="text"
            />

            <FormControl variant="outlined" fullWidth margin="dense" error={!!formik.touched.businessUnit && !!formik.errors.businessUnit}>
              <Typography variant="body2">{"Unidad de negocio"}</Typography>
              <Select
                value={formik.values.businessUnit === "" ? [""] : formik.values.businessUnit}
                onChange={(e) => {
                  formik.setFieldValue("businessUnit", e.target.value);
                }}
              >
                <MenuItem value="" disabled>
                  <em>Unidad de negocio *</em>
                </MenuItem>
                <MenuItem value='Empresarial'>Empresarial</MenuItem>
                <MenuItem value='Residencial'>Residencial</MenuItem>
              </Select>
              <FormHelperText>{!!formik.touched.businessUnit && formik.errors.businessUnit}</FormHelperText>
            </FormControl>

            <div className={classes.inputDoubleRow}>
            <FormControl variant="outlined" fullWidth margin="dense" error={!!formik.touched.area && !!formik.errors.area}>
              <Typography variant="body2">{"Área"}</Typography>
              <Select
                value={formik.values.area === "" ? [""] : formik.values.area}
                onChange={(e) => {
                  formik.setFieldValue("area", e.target.value);
                }}
              >
                <MenuItem value="" disabled>
                  <em>Área</em>
                </MenuItem>
                <MenuItem value='Operaciones'>Operaciones</MenuItem>
                <MenuItem value='Infraestructura'>Infraestructura</MenuItem>
                <MenuItem value='Distrito'>Distrito</MenuItem>
                <MenuItem value='Implementación'>Implementación</MenuItem>
              </Select>
              <FormHelperText>{!!formik.touched.area && formik.errors.area}</FormHelperText>
            </FormControl>

            <FormControl variant="outlined" fullWidth margin="dense" error={!!formik.touched.assignment && !!formik.errors.assignment}>
              <Typography variant="body2">{"Asignación"}</Typography>
              <Select
                value={formik.values.assignment === "" ? [""] : formik.values.assignment}
                onChange={(e) => {
                  formik.setFieldValue("assignment", e.target.value);
                }}
              >
                <MenuItem value="" disabled>
                  <em>Asignación *</em>
                </MenuItem>
                <MenuItem value='Directa (supervisor)'>{`Directa (supervisor)`}</MenuItem>
                <MenuItem value='Autorizado por compras'>Autorizado por compras</MenuItem>
                <MenuItem value='PIM'>PIM</MenuItem>
                <MenuItem value='PM'>PM</MenuItem>
              </Select>
              <FormHelperText>{!!formik.touched.assignment && formik.errors.assignment}</FormHelperText>
            </FormControl>
            </div>

          </div>

          <Grid container item xs={12} justifyContent="center">
            <div className="text-center">
              <Typography style={{ display: "block" }} variant="caption">
                * Campos obligatorios
              </Typography>
              <Button className="mt-2 ml-2" type="submit" variant="contained" color="primary" disabled={loading}>
                Siguiente
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop style={{ zIndex: 2000 }} open={loading} onClick={() => {}}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  formField: {
    border: `1px solid #2347AD`,
  },
  title: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: 600,
    color: theme.palette.black.main,
  },
  subtitle: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 600,
    color: theme.palette.black.main,
  },
  subContainer: {
    marginBottom: theme.spacing(2),
  },
  personSelect: {
    padding: theme.spacing(1),
  },
  inputDoubleRow: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gap: "1rem",
  },
  inputTripleRow: {
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    gap: "1rem",
  },
}));

export default FormGeneral;
