import React, { VFC } from "react";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import mime from "mime-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import RenderTextField from "./inputs/RenderTextField";
import RenderFileField from "./inputs/RenderFileField";
import MatFisButton from "./MatFisButton";
import { toBase64 } from "../utils";
import { DocumentsService } from "../../../core/services/documets.service";
import { NTPService } from "../../../core/services/NTPService.service";
import axios from "axios";
import useToast from "../../../core/hooks/useToast";
import useUiState from "../../../store/uiState";
import useGlobalState from "../../../store/globalState";

interface ContractAnnexFormDialogProps {
  open: boolean;
  onClose?: () => void;
  //onUploadFile: (contractForm: ContractForm) => void;
  serviceId: string;
  refresh: () => any;
}

interface AnnexForm {
  name: string;
  description?: string;
  annex: File;
}

const ContractAnnexFormDialog: VFC<ContractAnnexFormDialogProps> = (props) => {
  const classes = useStyles();
  const { Toast, showToast } = useToast();
  const setShowGenericLoader = useUiState((s) => s.setShowGenericLoader);
  const { typeService } = useGlobalState(); 

  const formik = useFormik<AnnexForm>({
    validateOnMount: true,
    initialValues: {
      name: "",
      description: "",
      annex: undefined,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Campo requerido"),
      annex: Yup.mixed()
        .required("Campo requerido")
        .test({
          name: "file-type",
          message: `El documento no es de tipo PDF`,
          test: (file) => file && mime.lookup(file?.name) === mime.lookup("pdf"),
        }),
    }),
    onSubmit: handleUpload,
  });

  const handleClose = () => {
    formik.setFieldValue("annex", undefined);
    if (props.onClose) props.onClose();
  };

  const handleChangeFile = (file?: File) => {
    formik.setFieldTouched("annex", true);
    formik.setFieldValue("annex", file);
  };

  async function handleUpload(values: AnnexForm) {
    try {
      const companyId = sessionStorage.getItem("companyId");
      props.onClose();
      setShowGenericLoader(true);

      if(typeService==="generators"){
        await uploadGeneradorAnnex(values, companyId, props.serviceId);
      }else{
        await uploadAnnex(values, companyId, props.serviceId);
      }

      props.refresh();
    } catch (error) {
      showToast("Ocurrió un error al subir el contrato", "error");
    } finally {
      setShowGenericLoader(false);
    }
  }

  async function uploadAnnex(values: AnnexForm, companyId: string, serviceId: string, folderId?: string) {
    if (values.annex) {
      const convertedDoc = (await toBase64(values.annex)) as string;
      const filename = values.annex.name.split(".") ?? ["", ""];
      const extension = `.${filename.pop()}` ?? "";
      const docBody: CreateDocumentReqBody = {
        companyId,
        description: values.description || "",
        extension,
        name: values.name ?? filename.join(),
        fileBase64: convertedDoc,
        status: true,
        folderId: folderId,
      };
      try {
        const vaultDoc = await DocumentsService.getInstance().createDocument(docBody);
        if (!vaultDoc.id) {
          showToast("Ocurrió un error al subir el anexo", "error");
          throw new Error("Hubo un error al intentar subir el archivo al vault");

        }
        const body = {
          fileId: vaultDoc.id,
          name: values.name,
          //comments: "",
          //month: "",
          //uid: "",
          //validationStatus: null,
          //validationType: null
        };

        try {
          const annex = await NTPService.getInstance().addContractAnnex(serviceId, body as any);
          if (!annex.uid) {
            throw new Error("Hubo un error al intentar subir el archivo al vault");
          } else {
            showToast("Anexo subido con éxito", "success");
          }
        } catch (error) {
          throw new Error("Hubo un error al intentar subir la información del contrato y de CFDI de nóminas.");
        }
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data.message === "File exist") {
          throw new Error("Ya existe un documento con ese nombre en la carpeta.");
        }
        throw new Error("Hubo un error al intentar subir el documento del contrato.");
      }
    } else throw new Error("Debe seleccionar un documento.");
  }

  async function uploadGeneradorAnnex(values: AnnexForm, companyId: string, serviceId: string, folderId?: string) {
    if (values.annex) {
      const convertedDoc = (await toBase64(values.annex)) as string;
      const filename = values.annex.name.split(".") ?? ["", ""];
      const extension = `.${filename.pop()}` ?? "";
      const docBody: CreateDocumentReqBody = {
        companyId,
        description: values.description || "",
        extension,
        name: values.name ?? filename.join(),
        fileBase64: convertedDoc,
        status: true,
        folderId: folderId,
      };
      try {
        const vaultDoc = await DocumentsService.getInstance().createDocument(docBody);
        if (!vaultDoc.id) {
          throw new Error("Hubo un error al intentar subir el archivo al vault");
        }
        const body = {
          fileId: vaultDoc.id,
          name: values.name,
          //comments: "",
          //month: "",
          //uid: "",
          //validationStatus: null,
          //validationType: null
        };

        try {
          const annex = await NTPService.getInstance().addGeneradorContractAnnex(serviceId, body as any);
          if (!annex.uid) {
            throw new Error("Hubo un error al intentar subir el archivo al vault");
          } else {
            showToast("Anexo subido con éxito", "success");
          }
        } catch (error) {
          throw new Error("Hubo un error al intentar subir la información del contrato y de CFDI de nóminas.");
        }
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data.message === "File exist") {
          throw new Error("Ya existe un documento con ese nombre en la carpeta.");
        }
        throw new Error("Hubo un error al intentar subir el documento del contrato.");
      }
    } else throw new Error("Debe seleccionar un documento.");
  }


  return (
    <>
      <Dialog open={props.open} maxWidth="sm">
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <span className={classes.dialogTitleText}>Subir anexo</span>
          <IconButton edge="end" onClick={handleClose}>
            <CloseIcon style={{ color: "#7A92C5" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit} className={classes.dialogContentRoot}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <RenderTextField
                  id="name"
                  label="Nombre del anexo"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  touched={Boolean(formik.touched.name)}
                  error={formik.errors.name as any}
                  placeholder="Nombre del anexo"
                />
              </Grid>
              <Grid item xs={12}>
                <RenderFileField
                  label="Anexo"
                  value={formik.values.annex}
                  onChange={handleChangeFile}
                  error={formik.errors.annex as any}
                  touched={formik.touched.annex as any}
                  accept="application/pdf"
                />
              </Grid>
            </Grid>
            <MatFisButton variant="contained" color="primary" disabled={!formik.isValid} type="submit">
              Subir
            </MatFisButton>
          </form>
        </DialogContent>
      </Dialog>
      <Toast />
    </>
  );
};


const useStyles = makeStyles({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogContentRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 20,
  },
  dialogTitleText: {
    fontWeight: 600,
    fontSize: "1.125rem",
    lineHeight: "21.33px",
  },
  dialogContentText: {
    fontSize: "0.875rem",
    lineHeight: "17px",
    color: "#7A92C5",
    marginBottom: 40,
  },
});
export default ContractAnnexFormDialog;
