/* eslint-disable no-useless-escape */
import { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  makeStyles,
  Backdrop,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Box,
  } from "@material-ui/core";
import * as Yup from "yup";
import TextField from "../../inputs/inputs-v2/RenderTextFieldV2";
import { useFormik } from "formik";
import { PhotographicFormInfoReqBody } from "../../../types/companies-service";
import CarouselPhotographic from "./CarouselPhotographic";
import { GeneradoresServices } from "../../../core/services/generadores.service";
import useGeneralFormCompleteState from "../../../store/generadores/generalFormState";
import useGeolocationFormState from "../../../store/generadores/geolocationFormState";
import usePhotographicFormState from "../../../store/generadores/photographicFormState";
import useGeneratorGlobalState from "../../../store/generadores/globalGeneradorState";
import useCustomToast from "../../../core/hooks/useCustomToast";
import PdfModal from "../../UI/PdfViewer";
import useUiState from "../../../store/uiState";

type FormFields = {
  rfc: string;
  date: string;
  name: string;
  code: string;
  address: string;
  ont: string;
};

function base64ToObjectURL(base64) {
  // Decodificar el string base64 y convertirlo en binario
  let byteString = atob(base64);
  let ab = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // Crear un Blob a partir de los datos binarios
  let blob = new Blob([ab], { type: 'image/png' });

  // Crear un ObjectURL a partir del Blob
  let url = URL.createObjectURL(blob);

  return url;
}

const FormPhotographic = ({onClose}) => {
  const generalFormBody = useGeneralFormCompleteState((state) => state.generalFormBody);
  const geolocationFormBody = useGeolocationFormState((state) => state.geolocationFormBody);
  const photographicFormBody = usePhotographicFormState((state) => state.photographicFormBody);
  const updatePhotographicBody = usePhotographicFormState((state) => state.updatePhotographicForm);
  const formControl = usePhotographicFormState((state) => state.formControl);
  const setFormControl = usePhotographicFormState((state) => state.setFormControl);
  const { createFinalGeneradorPDFandProcess  } = useGeneratorGlobalState();

  const classes = useStyles();
  const toast = useCustomToast();
  const [date, setDate] = useState<any>(geolocationFormBody.date);
  const idForm = useGeneralFormCompleteState((state) => state.idForm);
  //Estados de los arreglos de imagenes de cada seccion
  const [limit, setLimit] = useState<any>(false);
  const [trabajoAntes, setTrabajoAntes] = useState<any>(photographicFormBody.beforeList);
  const [trabajoDurante, setTrabajoDurante] = useState<any>(photographicFormBody.duringList);
  const [trabajoFinal, setTrabajoFinal] = useState<any>(photographicFormBody.endList);
  const [pdfView, setPdfView] = useState(false);
  const [dataUrl, setDataUrl] = useState('');
  const { setShowGenericLoader } = useUiState();

  useEffect(() => {
    setTimeout(async () => {
      await getImages();
    }, 0);
  }, []);

  const getImages = async () => {
    const beforeListTemporal = [];
    const duringListTemporal = [];
    const endListTemporal = [];
    setShowGenericLoader(true);
    try {
      if (photographicFormBody.beforeList.length > 0) {
        photographicFormBody.beforeList.map(async (item) => {
          if (item.imageId !== "string") {
            await GeneradoresServices.getInstance().getImage(item.imageId).then((data) => {
              const file = base64ToObjectURL(data.file);
              beforeListTemporal.push({imagen:file, ns: item.ns, activities: item.activities, imageId: item.imageId, disabled: true});
            })
          }
        });
      }
      if (photographicFormBody.duringList.length > 0) {
        photographicFormBody.duringList.map(async (item) => {
          if (item.imageId !== "string") {
            await GeneradoresServices.getInstance().getImage(item.imageId).then((data) => {
              const file = base64ToObjectURL(data.file);
              duringListTemporal.push({imagen:file, ns: item.ns, activities: item.activities, imageId: item.imageId, disabled: true});
            })
          }
        });
      }
      if (photographicFormBody.endList.length > 0) {
        photographicFormBody.endList.map(async (item) => {
          if (item.imageId !== "string") {
            await GeneradoresServices.getInstance().getImage(item.imageId).then((data) => {
              const file = base64ToObjectURL(data.file);
              endListTemporal.push({imagen:file, ns: item.ns, activities: item.activities, imageId: item.imageId, disabled: true});
            })
          }
        });
      }
      setTimeout(async () => {
        setShowGenericLoader(false);
        const count = photographicFormBody.beforeList.length + photographicFormBody.duringList.length + photographicFormBody.endList.length;
        if (count > 12) {
          toast.warnign("Has llegado al límite de fotografías.");
          setLimit(true);
        }
      }, (photographicFormBody.beforeList.length * 1000) +(photographicFormBody.duringList.length * 1000) + (photographicFormBody.endList.length * 1000));
      setTrabajoAntes(beforeListTemporal);
      setTrabajoDurante(duringListTemporal);
      setTrabajoFinal(endListTemporal);
    } catch (error) {
      toast.error("Ha ocurrido un error, verifique la información.");
    }
  };


  const handleSubmit = async (values: any) => {
    setShowGenericLoader(true);
    let beforeList = [];
    let duringList= [];
    let endList = [];
    trabajoAntes.map((item) => {
      if (item.imageId.length > 25) {
        beforeList.push({ns: item.ns, activities: item.activities, imageId: item.imageId});
      }
    });
    trabajoDurante.map((item) => {
      if (item.imageId.length > 25) {
        duringList.push({ns: item.ns, activities: item.activities, imageId: item.imageId});
      }
    });
    trabajoFinal.map((item) => {
      if (item.imageId.length > 25) {
        endList.push({ns: item.ns, activities: item.activities, imageId: item.imageId});
      }
    });

    try {
      const count = trabajoAntes.length + trabajoDurante.length + trabajoFinal.length;
      if (count <= 4) {
        toast.warnign("Debes subir minimo 5 fotografías.");
      } else if (count > 12) {
        toast.warnign("Debes subir máximo 12 fotografías.");
      } else if ((beforeList.length == 0) && (duringList.length == 0) && (endList.length == 0) 
        && (photographicFormBody.beforeList.length === 0) && (photographicFormBody.duringList.length === 0) && (photographicFormBody.endList.length === 0)) {
          toast.warnign("No debes subir ninguna lista vacía.");
      } else {
        await GeneradoresServices.getInstance().patchPhotographicImagesBeforeList(idForm, beforeList).then((data) => {
          beforeList = data.respuesta.photographic.beforeList;
        });
        await GeneradoresServices.getInstance().patchPhotographicImagesDuringList(idForm, duringList).then((data) => {
          duringList = data.respuesta.photographic.duringList;
        });
        await GeneradoresServices.getInstance().patchPhotographicImagesEndList(idForm, endList).then((data) => {
          endList = data.respuesta.photographic.endList;
        });

        const body: PhotographicFormInfoReqBody = {
          ont: values.ont,
          beforeList: beforeList,
          duringList: duringList,
          endList: endList
        };
        await GeneradoresServices.getInstance().patchPhotographicForm(idForm, body);
        updatePhotographicBody(body);
        toast.success("Información guardada con éxito.");
      }
      setShowGenericLoader(false);
    } catch (error) {
      setShowGenericLoader(false);
      toast.error("Ha ocurrido un error, verifique la información.");
    }
    
  };

  const formik = useFormik<FormFields>({
    initialValues: {
      rfc: generalFormBody.idCode,
      date: geolocationFormBody.date,
      name: generalFormBody.name,
      code: generalFormBody.idSupplier,
      address: generalFormBody.address,
      ont: photographicFormBody.ont,
    },
    onSubmit: handleSubmit,
    validationSchema: Yup.object({
      ont: Yup.string().required("Campo requerido"),
    }),
  });

  useEffect(() => {
    const count = trabajoAntes.length + trabajoDurante.length + trabajoFinal.length;
    if (count > 12) {
      toast.warnign("Has llegado al límite de fotografías.");
      setLimit(true);
    }
    const body: PhotographicFormInfoReqBody = {
      ont: photographicFormBody.ont,
      beforeList: trabajoAntes,
      duringList: trabajoDurante,
      endList: trabajoFinal
    };
    updatePhotographicBody(body);
  }, [trabajoAntes, trabajoDurante, trabajoFinal]);

  const viewPDF = async () => {
    try {
      const data = await GeneradoresServices.getInstance().getGeneratorPDF(idForm);
      const base64 = data.respuesta;
      const binaryString = atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const blob = new Blob([bytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      setDataUrl(url);
      setPdfView(true);
      setShowGenericLoader(false);
    } catch (error) {
      toast.error("Ha ocurrido un error al generar el PDF");
      setShowGenericLoader(false);
    }
  };

  /*function cortarString(input: string): string {
    const firstDots = input.indexOf(':');
    if (firstDots === -1) return input;
    const secondDots = input.indexOf(':', firstDots + 1);
    if (secondDots === -1) return input.slice(firstDots + 1).trim();
    const endString = input.indexOf('at com.', secondDots);
    if (endString === -1) return input.slice(secondDots + 1).trim();
  
    return input.slice(secondDots + 1, endString).trim();
  }*/
  
  /*function cortarString(input: string): string {
    //const match = input.match(/Exception:\s*([^\|]+)\|?\s*java\.lang\.Exception/);
    const match = input.match(/Exception:\s*([^\|]+)\|?\s*java/);

    // Verifica si se encontró el patrón |algo|
    if (match && match[1]) {
      console.log("match[1].trim()",match[1].trim())

      return match[1].trim(); // Retorna el contenido entre los pipes
    } else {
      return "Error al realizar el proceso.";
    }
  }*/

  function cortarString(input: string): string {
    const match = input.match(/Exception:\s*([^\|]+)\|?\s*java/);
    
    if (match && match[1]) {
      const extractedMessage = match[1].trim();
      console.log("match[1].trim()",extractedMessage)

      const newlineIndex = extractedMessage.indexOf('\n');
      if (newlineIndex !== -1) {
        return extractedMessage.slice(0, newlineIndex).trim(); 
      }
      console.log("match[1].trim() 3",extractedMessage)

      return extractedMessage; 
    } else {
      return "Error al realizar el proceso.";
    }
  }
  
  
  const sendFinalProcess = async () => {
    try {
      const data = await createFinalGeneradorPDFandProcess(idForm);
     if(data){
      setShowGenericLoader(false);
      toast.success("Proceso de firma generado, exitosamente");
      onClose();
     }
    } catch (error) {
      toast.error(cortarString(error.response.data.mensaje));
      setShowGenericLoader(false);
    }
  };
  
  return (
    <>
      {pdfView ? (
        <PdfModal open={pdfView} handleClose={() => {setPdfView(false);}} pdfBlobUrl={dataUrl}></PdfModal>
      ) : (
        <form className={classes.form} onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item md={12}>
            <div className={classes.subContainer}>
              <div className={classes.inputDoubleRow}>
                <TextField
                  label="RFC"
                  size="small"
                  onChange={formik.handleChange}
                  value={formik.values.rfc}
                  placeholder="RFC"
                  name="rfc"
                  disabled={true}
                />

                <TextField
                  size="small"
                  error={formik.errors.date}
                  touched={formik.touched.date}
                  value={date?.split("T")[0]}
                  onChange={(e) => {
                    const date = new Date(e.target.value);
                    date.setHours(24);
                    setDate(date?.toISOString().split("T")[0]);
                    formik.setFieldValue('date', date?.toISOString().split("T")[0]);
                  }}
                  type="date"
                  label="Fecha"
                  name="date"
                  minDate="2024-05-01"
                />
              </div>

              <div className={classes.inputDoubleRow}>
                <TextField
                  size="small"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  placeholder="Razón social"
                  name="name"
                  label="Razón social"
                  disabled={true}
                />

                <TextField
                  size="small"
                  onChange={formik.handleChange}
                  value={formik.values.code}
                  placeholder="ID del proveedor"
                  label="ID del proveedor"
                  name="code"
                  disabled={true}
                />
              </div>

              <TextField
                  size="small"
                  onChange={formik.handleChange}
                  value={formik.values.address}
                  placeholder="Dirección del cliente "
                  label="Dirección del cliente "
                  name="address"
                  disabled={true}
                />

              <div className={classes.inputDoubleRow}>
                <TextField
                  size="small"
                  error={formik.errors.ont}
                  onChange={formik.handleChange}
                  touched={formik.touched.ont}
                  value={formik.values.ont}
                  placeholder="ONT"
                  label="ONT"
                  name="ont"
                />
                  <Button type="submit" variant="contained" color="primary" style={{width: '150px'}}>
                    Guardar cambios
                  </Button>
              </div>

              <div className={classes.separator} />
              <CarouselPhotographic 
                formik={formik}
                label="Trabajo antes"
                imagesArray={trabajoAntes}
                imagesArraySecond={trabajoDurante}
                imagesArrayThird={trabajoFinal}
                setImagesArray={setTrabajoAntes}
                list="beforeList"
                limit={limit}
              />

              <div className={classes.separator} />
              <CarouselPhotographic 
                formik={formik}
                label="Trabajo durante"
                imagesArray={trabajoDurante}
                imagesArraySecond={trabajoAntes}
                imagesArrayThird={trabajoFinal}
                setImagesArray={setTrabajoDurante}
                list="duringList"
                limit={limit}
              />

              <div className={classes.separator} />
              <CarouselPhotographic 
                formik={formik}
                label="Trabajo final"
                imagesArray={trabajoFinal}
                imagesArraySecond={trabajoDurante}
                imagesArrayThird={trabajoAntes}
                setImagesArray={setTrabajoFinal}
                list="endList"
                limit={limit}
              />
              <div className={classes.separator} />
            </div>

            <Grid container item xs={12} justifyContent="center">
              <div>
                <FormControlLabel
                  control={<Checkbox onClick={() => {setFormControl();}} color="primary"  checked={!formControl} />}
                  label={(
                    <Typography variant="caption" style={{ display: "block",  textAlign: 'center',}}>
                      Al aceptar el campo de validación de obra concluida doy mi aprobación y me hago responsable de que estos trabajos se realizaron en tiempo y forma con los materiales y servicios a continuación mencionados.
                    </Typography>
                  )}
                />
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-around'}}>
                  <Button 
                    onClick={() => {
                      setShowGenericLoader(true);
                      viewPDF();
                    }} 
                    className="mt-2 ml-5" variant="contained" color="primary" style={{width: '150px'}}>
                    Previsualizar
                  </Button>

                  <Button disabled={formControl}    
                    onClick={() => {
                      setShowGenericLoader(true);
                      sendFinalProcess();
                    }}  className="mt-2 mr-5" variant="contained" color="primary" style={{width: '150px'}}>
                    Enviar
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </form>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  subContainer: {
    marginBottom: theme.spacing(2),
  },
  separator: {
    width: '100%',
    borderTop: `2px solid #2347AD`,
    marginTop: '5px',
  },
  inputDoubleRow: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gap: "1rem",
  },
  dropdown: {
    marginBottom: theme.spacing(2),
  },
}));

export default FormPhotographic;
